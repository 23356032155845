import {Component, Input, OnInit} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {Observable} from "rxjs";
import {User} from "../../../models/user";
import {v4 as uuidv4} from "uuid";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/app-state";
import {PanelService} from "../../../services/panel/panel.service";

@Component({
    selector: 'app-modal-reallocate',
    templateUrl: './modal-reallocate.component.html',
    styleUrls: ['./modal-reallocate.component.scss'],
})
export class ModalReallocateComponent implements OnInit {
    @Input() callbackConfirmation?: Function
    @Input() title?: string
    @Input() desc?: string
    @Input() patientId: string = ''
    @Input() appointmentType: string = ''

    private user$?: Observable<User>
    user?: User

    private uuid: string = ''
    qrCode: string = ''

    constructor(public activeModal: NgbActiveModal,
                private store: Store<AppState>,
                private panelService: PanelService,
    ) {
        this.user$ = this.store.select('user')
        this.user$.subscribe((state) => {
            this.user = state
        })

        setTimeout(() => {
            this.qrCode = this.generateQrCode()
        }, 150)
    }

    ngOnInit(): void {
    }

    close() {
        this.activeModal.close()
    }

    generateQrCode(): string {
        this.uuid = uuidv4();

        const qrCodeData = {
            session_id: this.uuid,
            cabin_id: this.user?.id,
            client_id: this.user?.client?.id,
            patient_id: this.patientId,
            reallocate: true,
            appointment_type: this.appointmentType
        };

        this.panelService.setQrCodeLocale(this.uuid)
        return JSON.stringify(qrCodeData)
    }

    changeValueAppointmentType() {
        this.qrCode = this.generateQrCode()
    }

    confirmation() {
        if (this.callbackConfirmation) this.callbackConfirmation()
        this.close()
    }
}
