<div class="wrapper-status fixed-bottom">
    <div class="status-login">
        <p [class.active]="status">
            {{ status ? 'online' : 'offline' }}
        </p>
    </div>
    <div class="status-app">
        <app-version></app-version>
    </div>
</div>
