<div class="wrapper-table custom-scroll">
	<table class="table">
		<thead>
			<tr>
				<th
					scope="col"
					*ngFor="let item of listHeader"
					[attr.data-value]="item.value"
                    [attr.align]="item.align"
                    [class]="item.className"

				>
					{{ item.label }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="
					let item of listBody
						| slice
							: (page - 1) * pageSize
							: (page - 1) * pageSize + pageSize
				"
			>
				<ng-container
					*ngIf="bodyTemplateRef"
					[ngTemplateOutlet]="bodyTemplateRef"
					[ngTemplateOutletContext]="{ $implicit: item }"
				>
				</ng-container>
			</tr>
		</tbody>
	</table>
</div>
<div>
	<ngb-pagination
		[collectionSize]="listBody?.length || 0"
		[(page)]="page"
		[pageSize]="pageSize"
		[maxSize]="5"
	></ngb-pagination>
</div>
