import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-visual-acuity',
    templateUrl: './visual-acuity.component.html',
    styleUrls: ['./visual-acuity.component.scss']
})
export class VisualAcuityComponent implements OnInit {
    listRowLength: number = 11
    lettersDefault: string[] = ['C', 'D', 'E', 'F', 'L', 'O', 'P', 'T', 'Z']
    letters: any = []

    constructor() {
    }

    ngOnInit(): void {
        this.letters = this.generateList()
    }

    generateList(): string[] {
        const list: any = []
        let c = 0
        while (c < this.listRowLength) {
            let items: string[] = []
            for (let i = 0; i <= c; i++) {
                let itemRandom: string = 'E'
                if (c !== 0) {
                    itemRandom = this.getItemRandom(this.lettersDefault)
                }
                items.push(itemRandom)
            }
            list.push(items)
            c++
        }

        return list
    }

    shuffleList(): void {
        this.letters = this.generateList()
    }

    getItemRandom(list: any[]): string {
        return list[Math.floor(Math.random() * list.length)]
    }

    getSize(rowNumber: number): any {
        let obj: any = {
            size: '',
            fontSize: ''
        }
        switch (rowNumber) {
            case 1:
                obj = {
                    size:'20/200',
                    fontSize: '69'
                }
                break;
            case 2:
                obj = {
                    size:'20/100',
                    fontSize: '37'
                }
                break;
            case 3:
                obj = {
                    size:'20/70',
                    fontSize: '21'
                }
                break;
            case 4:
                obj = {
                    size:'20/50',
                    fontSize: '18'
                }
                break;
            case 5:
                obj = {
                    size:'20/40',
                    fontSize: '14'
                }
                break;
            case 6:
                obj = {
                    size:'20/30',
                    fontSize: '12'
                }
                break;
            case 7:
                obj = {
                    size:'20/25',
                    fontSize: '10'
                }
                break;
            case 8:
                obj = {
                    size:'20/20',
                    fontSize: '8'
                }
                break;
            case 9:
                obj = {
                    size:'20/15',
                    fontSize: '6'
                }
                break;
            case 10:
                obj = {
                    size:'20/13',
                    fontSize: '4'
                }
                break;
            case 11:
                obj = {
                    size:'20/10',
                    fontSize: '3.5'
                }
        }
        return obj
    }

}
