import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {HelpersService} from '../../services/helpers/helpers.service'

@Component({
    selector: 'app-patient-info',
    templateUrl: './patient-info.component.html',
    styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent implements OnInit {
    @Output() public onConfirm: EventEmitter<any> = new EventEmitter()
    @Output() public isShowMenuChanged: EventEmitter<void> = new EventEmitter();

    @Input()
    patientValues: any[] = []

    @Input()
    isShowMenu: boolean = false;
    @Input()
    imgOpen?: string = "";
    @Input()
    imgClose?: string = "";

    constructor(public helpers: HelpersService) {}

    ngOnInit(): void {
    }

    toggleMenuPatientInfo() {
        this.isShowMenuChanged.emit();
    }

    confirmation() {
        this.onConfirm.emit('personalDataConfirm')
    }
}
