import {Component, OnInit} from '@angular/core'
import {Socket} from "socket.io-client/build/esm/socket";
import {SocketIoService} from "../../services/socket-io/socket-io.service";

@Component({
    selector: 'app-status-login',
    templateUrl: './status-login.component.html',
    styleUrls: ['./status-login.component.scss'],
})
export class StatusLoginComponent implements OnInit {
    status: boolean = false

    private socket?: Socket

    constructor(private socketIoService: SocketIoService) {
    }

    private watchWebSocket(): void {
        this.socket = this.socketIoService.getSocket()

        this.socket?.on('connect', () => {
            this.status = true
        })
    }

    ngOnInit(): void {
        this.watchWebSocket()
    }

    ngOnDestroy(): void {
        this.socket?.close()
        this.status = false
    }
}
