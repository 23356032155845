import {
	Component,
	ContentChild,
	Input,
	OnInit,
	TemplateRef,
} from '@angular/core'
import { ListHeader } from '../../models/list-header'

@Component({
	selector: 'app-table-list',
	templateUrl: './table-list.component.html',
	styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent implements OnInit {
	@Input() listHeader: ListHeader[] | undefined = []

	@Input() listBody?: any = []

	@ContentChild('body', { static: false }) bodyTemplateRef?: TemplateRef<any>
	page = 1
	pageSize = 10

	constructor() {}

	ngOnInit(): void {
    }
}
