import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Session} from "../../models/session";
import {environment} from "../../../environments/environment";
import {HelpersService} from "../helpers/helpers.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {HiddenLoading, ShowLoading} from "../../state/loading/loading.action";
import {finalize} from "rxjs/operators";
import {AddPatient, ClearPatient} from "../../state/patient/patient.action";
import {StepsAppointment} from "../../models/stepsAppointment";
import {AddStepsAppointment, ClearStepsAppointment} from "../../state/steps-appointment/steps-appointment.action";
import {AppState} from "../../state/app-state";
import {AppointmentsService} from "../appointments/appointments.service";
import {computeStartOfLinePositions} from "@angular/compiler-cli/src/ngtsc/sourcemaps/src/source_file";
import {Callbacks} from "../../models/callbacks";

@Injectable({
    providedIn: 'root',
})
export class PanelService {
    private keyQrCode: string = 'qrCode'
    private keySession: string = 'session'
    private qrCodeRead: string = 'qrCodeRead'
    private steps: string = 'steps'

    constructor(
        private http: HttpClient,
        private helpers: HelpersService,
        private appointmentsService: AppointmentsService,
        private router: Router,
        private store: Store<AppState>
    ) {
    }

    setQrCodeLocale(value: string): void {
        localStorage.setItem(this.keyQrCode, value)
    }

    getQrCodeLocale(): string {
        return localStorage.getItem(this.keyQrCode) || ''
    }

    deleteQrCodeLocale(): void {
        localStorage.removeItem(this.keyQrCode)
    }

    setSessionLocale(session: Session): void {
        localStorage.setItem(this.keySession, JSON.stringify(session))
    }

    getSessionLocale(): Session | null {
        const session = localStorage.getItem(this.keySession) || null
        if (!session) return null
        return JSON.parse(session)
    }

    deleteSessionLocale(): void {
        localStorage.removeItem(this.keySession)
    }

    setHaveQrCode() {
        localStorage.setItem(this.qrCodeRead, JSON.stringify(true))
    }

    haveQrCodeRead() {
        return localStorage.getItem(this.qrCodeRead) || false
    }

    deleteQrCodeRead() {
        localStorage.removeItem(this.qrCodeRead)
    }

    goToPanelExams(isReallocate: boolean = false) {
        this.setHaveQrCode()
        if (isReallocate) {
            this.deleteSessionLocale()
            this.deleteStepsLocale()
            this.router.navigate(['/painel', {reallocate: isReallocate}])
        } else {
            this.router.navigate(['/painel'])
        }
    }

    setStepsAppointment(value: StepsAppointment): void {
        let steps: StepsAppointment[] = this.getStepsAppointmentLocale()
        value.active = true

        if (value.name === 'Exames') {
            let keyExisted = false
            steps = steps.map((item) => {
                item.active = false

                if (item.name === 'Exames') {
                    keyExisted = true
                    const itemInValue = value.list?.length ? value.list[0] : {name: ''}
                    const index = item.list?.findIndex((subItem) => subItem.name === itemInValue.name)
                    if (index === -1) {
                        item.list = [...item.list || [], ...value.list || []]
                        item.list[item.list.length - 1].active = true
                    } else {
                        if (item.list?.length && index !== undefined) {
                            item.list?.forEach((subItem, i) => {
                                subItem.active = false
                            })

                            item.list[index].active = true
                        }
                    }

                    item.active = false
                }
                return item
            })
            if (!keyExisted) {
                steps.push(value)
            }
        } else {
            steps = steps.map((item) => {
                item.active = false

                if (item.name === 'Exames') {
                    item.list = item.list?.map((subItem) => {
                        subItem.active = false
                        return subItem
                    })
                }
                return item
            })

            const index = steps.findIndex((item) => item.name === value.name)
            if (index === -1) {
                steps.push(value)
            } else {
                steps[index] = {...steps[index], active: true}
            }
        }
        const session = this.getSessionLocale()
        const strSteps = JSON.stringify(steps)
        localStorage.setItem(this.steps, strSteps)
        this.store.dispatch(AddStepsAppointment(steps))

        if (session) {
            this.appointmentsService.setStepsAppointment(session, strSteps)
        }
    }

    getStepsAppointmentLocale(): StepsAppointment[] {
        const steps = localStorage.getItem(this.steps) || '[]'
        this.store.dispatch(AddStepsAppointment(JSON.parse(steps)))
        return JSON.parse(steps)
    }

    deleteStepsLocale(): void {
        localStorage.removeItem(this.steps)
        this.store.dispatch(ClearStepsAppointment())
    }

    setSession(data: any) {
        const session: Session = {
            ...this.helpers.converterSnakeToCamelCase(data)
        }
        session.patient = this.helpers.converterSnakeToCamelCase(session.patient)
        this.store.dispatch(AddPatient(session.patient))

        if (session.doctor) {
            session.doctor = this.helpers.converterSnakeToCamelCase(session.doctor)
        }

        session.attendant = this.helpers.converterSnakeToCamelCase(session.attendant)

        this.deleteQrCodeLocale()
        this.setSessionLocale(session)
    }

    getSession(callbacks?: Callbacks): void {
        const qrCode = this.getQrCodeLocale()
        if (!qrCode) {
            callbacks?.fnSuccess()
            return
        }

        this.store.dispatch(ShowLoading())
        this.http
            .get<any>(`${environment.api}/appointments/hash/${qrCode}`)
            .pipe(finalize(() => {
                this.store.dispatch(HiddenLoading())
            }))
            .subscribe((response) => {
                const {data} = response

                this.setSession(data)

                callbacks?.fnSuccess()

                this.deleteQrCodeLocale()
                if (this.router.url !== '/painel') {
                    this.router.navigate(['/painel'])
                }
            }, (error => {
                // this.deleteSessionLocale()
                console.warn(error)
                callbacks?.fnError(error)
            }))
    }

    cancelSession(withRedirect: boolean = false): void {
        this.deleteQrCodeLocale()
        this.deleteSessionLocale()
        this.deleteQrCodeRead()
        this.deleteStepsLocale()
        this.clearAll()

        if (withRedirect) {
            // @ts-ignore
            window.location = '/qr-code'
        }
    }

    clearAll() {
        this.store.dispatch(ClearPatient())
    }
}
