import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {HelpersService} from '../helpers/helpers.service'
import {Router} from '@angular/router'
import {Stats} from '../../models/stats'
import {environment} from '../../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class StatsService {
    constructor(
        private http: HttpClient,
        private helpers: HelpersService,
        private router: Router
    ) {
    }

    stats(
        category: string,
        type: string,
        appointmentId?: string,
        label?: string
    ): void {
        const token = localStorage.getItem('token') || ''
        let payload: Stats = {
            access_point: {
                type: 'cabin',
            },
            event: {
                page: this.router.url,
                category,
                label,
                type,
            },
        }

        if (appointmentId) {
            payload = {...payload, appointment_id: appointmentId}
        }

        if (token) {
            const {user} = this.helpers.decodePayloadJWT(token)

            payload = {
                ...payload,
                company_id: user.client?.id,
                operator: {
                    id: user.id,
                    type: 'cabin',
                },
            }

            try {
                this.http
                    .post(`${environment.api}/events`, payload)
                    .subscribe(
                        () => {
                        },
                        (err) => {
                            console.warn(err)
                        }
                    )
            } catch (e) {
                //
            }
        }
    }
}
