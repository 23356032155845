import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {HelpersService} from '../helpers/helpers.service'
import {environment} from '../../../environments/environment'
import {Callbacks} from "../../models/callbacks";
import {finalize} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {HiddenLoading, ShowLoading, ShowLoadingProgress} from "../../state/loading/loading.action";
import {AppState} from "../../state/app-state";
import {Session} from "../../models/session";

@Injectable({
    providedIn: 'root',
})
export class AppointmentsService {

    constructor(
        private http: HttpClient,
        private helpers: HelpersService,
        private store: Store<AppState>,
    ) {
    }

    getAppointment(id: string, callbacks: Callbacks): void {
        this.store.dispatch(ShowLoading())

        this.http
            .get<any>(`${environment.api}/appointments/${id}`)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                    this.store.dispatch(HiddenLoading())
                })
            )
            .subscribe(
                (response: any) => {
                    console.log('GET APPOINTMENT SERVICE: ', response)
                    callbacks.fnSuccess(response)
                },
                (err) => {
                    console.warn(err)
                    callbacks.fnError(err)
                }
            )
    }

    getAppointments(callbacks: Callbacks): void {
        const token = localStorage.getItem('token') || ''

        if (token) {
            this.store.dispatch(ShowLoading())
            const {user} = this.helpers.decodePayloadJWT(token)

            this.http
                .get<any>(`${environment.api}/appointments`, {
                    params: {
                        origin: user.id,
                        status: 'finalized',
                        page_size: 200,
                    }
                })
                .pipe(
                    finalize(() => {
                        if (callbacks.fnFinalized) callbacks.fnFinalized()
                        this.store.dispatch(HiddenLoading())
                    })
                )
                .subscribe(
                    (response: any) => {
                        console.log('GET APPOINTMENTS SERVICE: ', response)
                        callbacks.fnSuccess(response)
                    },
                    (err) => {
                        console.warn(err)
                        callbacks.fnError(err)
                    }
                )
        }
    }

    getExternalAppointment(id: string, callbacks: Callbacks) {
        this.store.dispatch(ShowLoading())

        this.http
            .get<any>(`${environment.api}/appointments/${id}/external_appointment`)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                    this.store.dispatch(HiddenLoading())
                })
            )
            .subscribe(
                (response: any) => {
                    console.log('GET APPOINTMENT EINSTEIN SERVICE: ', response)
                    callbacks.fnSuccess(response)
                },
                (err) => {
                    console.warn(err)
                    callbacks.fnError(err)
                }
            )
    }

    getTokenVideoCall(hash: string, callbacks: Callbacks): void {
        this.store.dispatch(ShowLoadingProgress())

        this.http
            .get<any>(`${environment.api}/appointments/hash/${hash}/twilio`)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                    this.store.dispatch(HiddenLoading())
                })
            )
            .subscribe(
                (response: any) => {
                    let {data} = response
                    data = this.helpers.converterSnakeToCamelCase(data)
                    console.log('GET TOKEN VIDEO CALL SERVICE: ', data)
                    callbacks.fnSuccess(data)
                },
                (err) => {
                    console.warn(err)
                    callbacks.fnError(err)
                }
            )
    }

    setStepsAppointment(session: Session, data: string) {
        const body = {
            completed_steps: data
        }

        this.http
            .patch<any>(`${environment.api}/appointments/${session.id}`, body)
            .subscribe(
                (response: any) => {
                    console.log('SET STEP APPOINTMENT SERVICE', response)
                },
                (err) => {
                    console.warn(err)
                }
            )
    }

    patchAppointment(session: Session, body: any, callbacks: Callbacks) {
        this.http
            .patch<any>(`${environment.api}/appointments/${session.id}`, body)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe(
                (response: any) => {
                    console.log('APPOINTMENT SERVICE', response)
                    callbacks.fnSuccess(response)
                },
                (err) => {
                    console.warn(err)
                    callbacks.fnError(err)
                }
            )
    }

    screeningCompletedAppointment(session: Session, callbacks: Callbacks) {
        const body = {
            screening_completed: true
        }

        this.patchAppointment(session, body, callbacks)
    }

    cancelAppointment(session: Session, callbacks: Callbacks) {
        const body = {
            status: 'canceled'
        }

        this.patchAppointment(session, body, callbacks)
    }
}
