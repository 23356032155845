<audio #audioElement src="{{ src }}" [attr.data-src]="src">
	<p>
		Seu navegado não suporta audio HTML5.
		<a href="{{ src }}" [download]="src">Clique aqui</a> e faça o download
		do audio.
	</p>
</audio>
<div class="wrapper-audio">
	<h6 *ngIf="title">{{ title }}</h6>
	<span class="audio">
		<button (click)="paused ? play() : pause()" type="button">
			<img
				[src]="
					paused
						? '../../..assets/images/controls/play-circle.png'
						: '../../..assets/images/controls/pause-circle.png'
				"
				[alt]="paused ? 'Play' : 'Pause'"
				[title]="paused ? 'Play' : 'Pause'"
			/>
		</button>
		<div class="sidebar">
			<input
				#seek
				type="range"
				[(ngModel)]="current"
				[max]="duration"
				step="1"
				(ngModelChange)="setDuration(seek.value)"
			/>
		</div>
		<span>{{ getTimeCodeFromNum(current) }}s</span>
	</span>
</div>
