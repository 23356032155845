import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-select-exam',
	templateUrl: './select-exam.component.html',
	styleUrls: ['./select-exam.component.scss'],
})
export class SelectExamComponent implements OnInit {
	@Input() image?: string = ''
	@Input() title?: string = ''
	@Input() theme?: string = 'default'
	@Input() active?: boolean = false

	constructor() {}

	ngOnInit(): void {}
}
