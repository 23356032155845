import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'

@Component({
	selector: 'app-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	styleUrls: ['./sidebar-item.component.scss'],
})
export class SidebarItemComponent implements OnInit {
	@Input() image: string = ''
	@Input() imageActive: string = ''
	@Input() title: string = ''
	@Input() link?: string = ''
	@Input() active?: boolean = false
	@Input() isButton?: boolean = true
	@Input() disabled?: boolean = false
	@Input() isTargetBlank?: boolean = false
	@Input() handler?: Function

	@ViewChild('element', { static: false })
	public _elementRef?: ElementRef
	private element?: ElementRef
	@Output() emmitEventSelect: EventEmitter<any> = new EventEmitter()

	currentRoute?: string

	constructor(private router: Router) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.currentRoute = this.router.url
			}
		})
	}

	ngOnInit(): void {}

	public ngAfterViewInit() {
		this.element = this._elementRef
	}

	fnHandler(): void {
		if (this.handler) this.handler()
	}

	selectedItem() {
		this.emmitEventSelect.emit('selectedItem')
	}
}
