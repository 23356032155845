<div
	[class]="
		isShowMenu ? 'wrapper__menu' : 'wrapper__menu wrapper__menu__close'
	"
>
	<button (click)="toggleMenuPatientInfo()" class="menu-button">
		<img
			[src]="isShowMenu ? imgClose : imgOpen"
			alt="Botão para o menu com os dados pessoais"
		/>
	</button>

	<div class="wrapper__menu__title">
		<span>Dados coletados</span>
	</div>
	<div class="wrapper__menu__content">
		<ul>
			<li *ngFor="let item of patientValues">
                <span>{{item.label}}: </span>{{item.value}}
            </li>
		</ul>
	</div>
	<button class="button__submit btn-primary" (click)="confirmation()">CONFIRMAR</button>
</div>
