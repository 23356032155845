import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
	constructor(private router: Router, private statsService: StatsService) {}

	ngOnInit(): void {
		this.statsService.stats('init_page_not_fund', 'start')
	}

	ngOnDestroy() {
		this.statsService.stats('init_page_not_fund', 'and')
	}

	backToPage(): void {
		this.router.navigate(['..'])
	}
}
