import {Component, Input, OnInit} from '@angular/core'
import {PanelService} from '../../services/panel/panel.service'
import {Session} from '../../models/session'
import {ExamsService} from '../../services/exams/exams.service'
import {Exam} from '../../models/exam'
import {HelpersService} from '../../services/helpers/helpers.service'
import {ActivatedRoute, Router} from '@angular/router'
import {SocketIoService} from '../../services/socket-io/socket-io.service'
import {ToastrService} from 'ngx-toastr'
import {StatsService} from '../../services/stats/stats.service'
import {AppointmentsService} from "../../services/appointments/appointments.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalTryAgainComponent} from "../../components/modals/modal-try-again/modal-try-again.component";
import {Client} from "../../models/client";
import {AuthService} from "../../services/auth/auth.service";

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
    @Input() config?: boolean

    private session?: Session | null
    private client?: Client | null

    exam?: Exam

    isShowPatientInfoMenu: boolean = false

    isReallocate: boolean = false

    examIsCabin: boolean = false
    isWelcome: boolean = true
    isPatientInfo: boolean = false
    isRecordingCompliance: boolean = false
    specialtySelected: boolean = false
    isWaiting: boolean = false
    yourTurnHasCome: boolean = false

    patientValues: any[] = []

    specialty: string = ''

    loading: boolean = false

    timeout?: any

    _reload: boolean = true

    modalRef: any

    constructor(
        private panelService: PanelService,
        private appointmentsService: AppointmentsService,
        public examsService: ExamsService,
        private helpers: HelpersService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private socketIoService: SocketIoService,
        private toast: ToastrService,
        private statsService: StatsService,
        private modalTryAgain: NgbModal,
        private authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.init()
    }

    ngOnDestroy() {
        clearTimeout(this.timeout)
        this.session = null
        this.statsService.stats('init_exams', 'end')
    }

    init() {
        const initProcess = this.panelService.haveQrCodeRead()
        this.session = this.panelService.getSessionLocale()
        this.client = this.authService.getClientLocale()

        this.activatedRoute.params.subscribe((params) => {
            this.isReallocate = params.reallocate ? JSON.parse(params.reallocate) : false
        })

        if (initProcess || this.session) {
            this.openScreen()
            this.watchEventsSocket()
        } else {
            this.toast.error(
                'Você não pode iniciar uma sessão sem permissão ou sem QrCode!'
            )
            this.panelService.cancelSession(true)
        }
    }

    openScreen() {
        const steps = this.panelService.getStepsAppointmentLocale()
        this.statsService.stats('init_exams', 'start')

        if (steps.length == 2) {
            this.patientInfo()
        } else if (steps.length > 2) {
            this.waiting()
        } else {
            this.welcome()
        }
    }

    watchEventsSocket() {
        this.socketIoService.watchEventsSocket('appointmentCanceled', () => {
            this.toast.warning('Consulta cancelado!')
            this.panelService.cancelSession(true)
        })

        this.socketIoService.watchEventsSocket('appointmentFinalized', () => {
            this.fnAppointmentFinalized()
        })

        this.socketIoService.watchEventsSocket(
            'personalData',
            (response: any) => {
                let {body} = response.content
                if (body) {
                    body = JSON.parse(body)
                    this.patientValues = []

                    Object.keys(body).forEach((key) => {
                        this.patientValues?.push({
                            label: key,
                            value: body[key],
                        })
                    })
                    this.openPatientInfoMenu()
                }
            }
        )

        this.socketIoService.watchEventsSocket('personalDataSuccess', () => {
            this.panelService.getSession()
            this.waiting()
        })

        this.socketIoService.watchEventsSocket('complaintRecording', () => {
            this.recordingCompliance()
            this.panelService.setStepsAppointment({name: 'Queixa'})
            this.socketIoService.emitEventSocket(
                'updatePatientList',
                null,
                'panel_cmd'
            )
        })

        this.socketIoService.watchEventsSocket(
            'complaintRecordingSuccess',
            () => {
                this.waiting()
            }
        )

        this.socketIoService.watchEventsSocket(
            'specialtySelected',
            (response: any) => {
                const {specialty} = response.content
                this.specialty = specialty
                this.showSpecialty()
                this.panelService.setStepsAppointment({name: 'Especialidade'})
                this.socketIoService.emitEventSocket(
                    'updatePatientList',
                    null,
                    'panel_cmd'
                )
            }
        )

        this.socketIoService.watchEventsSocket(
            'examSelected',
            (response: any) => {
                const {data} = response.content
                this.setExam(data)
                this.panelService.setStepsAppointment({
                    name: 'Exames',
                    list: [data],
                })
            }
        )

        this.socketIoService.watchEventsSocket(
            'examVisualAcuityRandomList',
            () => {
                this.reload()
            }
        )

        this.socketIoService.watchEventsSocket('examFinalized', () => {
            this.waiting()
        })

        this.socketIoService.watchEventsSocket('yourTurn', () => {
            this.goToAppointment()
            this.panelService.setStepsAppointment({name: 'Teleatendimento'})
        })
    }

    setExam(exam?: any) {
        this.resetAll()

        if (exam) {
            this.reload()
            exam.children = exam?.children?.map((item: any) => {
                return {
                    ...this.helpers.converterSnakeToCamelCase(item),
                }
            })

            this.exam = {
                ...this.helpers.converterSnakeToCamelCase(exam),
            }
        }

        this.examIsCabin = this.exam?.capturedBy === 'cabin'

        if (this.examIsCabin) {
            this.socketIoService.emitEventSocket('examInitialized', {
                data: this.exam,
            })
        }
    }

    finishExam() {
        if (this.examIsCabin) {
            this.socketIoService.emitEventSocket('examFinalized', {
                data: this.exam,
            })
        }
        this.waiting()
    }

    personalDataConfirm() {
        this.socketIoService.emitEventSocket('personalDataConfirm')
    }

    welcome() {
        clearTimeout(this.timeout)
        this.resetAll()
        this.isWelcome = true
        this.panelService.setStepsAppointment({name: 'Boas vindas'})

        this.timeout = setTimeout(() => {
            this.patientInfo()
        }, 5000)
    }

    patientInfo() {
        this.resetAll()
        this.isPatientInfo = true
        this.panelService.setStepsAppointment({name: 'Dados pessoais'})
    }

    openPatientInfoMenu() {
        this.isShowPatientInfoMenu = true
    }

    closePatientInfoMenu() {
        this.isShowPatientInfoMenu = false
    }

    togglePatientInfoMenu() {
        this.isShowPatientInfoMenu = !this.isShowPatientInfoMenu
    }

    recordingCompliance() {
        clearTimeout(this.timeout)
        this.resetAll()
        this.isRecordingCompliance = true
    }

    showSpecialty() {
        clearTimeout(this.timeout)
        this.resetAll()
        this.specialtySelected = true

        this.timeout = setTimeout(() => {
            this.waiting()
        }, 5000)
    }

    waiting() {
        clearTimeout(this.timeout)
        this.resetAll()
        this.isWaiting = true
    }

    goToAppointment() {
        const self = this
        clearTimeout(self.timeout)
        self.resetAll()
        self.yourTurnHasCome = true

        self.panelService.getSession({
            fnSuccess() {
                self.timeout = setTimeout(() => {
                    // @ts-ignore
                    window.location = '/consulta'
                }, 5000)
            },
            fnError(error) {
                self.toast.error('Erro ao tentar ir para o teleatendimento')
            }
        })
    }

    fnAppointmentFinalized() {
        this.session = this.panelService.getSessionLocale()

        if (this.session?.type === 'exams' && this.client?.externalConference) {
            const self = this
            if (self.session?.id) {
                self.appointmentsService.getExternalAppointment(self.session.id, {
                    fnSuccess(data?: any) {
                        self.toast.warning('Consulta encerrada!')

                        const url = data.url;
                        window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=4000,height=4000');
                        self.panelService.cancelSession(true)
                    },
                    fnError(error) {
                        if(error.status === 424) {
                            self.toast.error(`Erro no módulo ${self.session?.client?.name}!`)
                        } else {
                            self.toast.error('Tente novamente!', 'Error Server')
                        }
                        self.modalRef = self.modalTryAgain.open(ModalTryAgainComponent)
                        self.modalRef.componentInstance.title = 'Tente novamente'
                        self.modalRef.componentInstance.desc = 'Ocorreu um erro ao tentar gerar o link para o teleatendimento'
                        self.modalRef.componentInstance.callbackConfirmation = () => {
                            self.fnAppointmentFinalized()
                        }
                        self.modalRef.componentInstance.callbackCancel = () => {
                            self.panelService.cancelSession(true)
                        }
                    },
                })
            }
        } else {
            this.toast.warning('Consulta encerrada!')
            this.panelService.cancelSession(true)
        }
    }

    private reload() {
        setTimeout(() => (this._reload = false))
        setTimeout(() => (this._reload = true))
    }

    resetAll() {
        this.exam = undefined
        this.examIsCabin = false
        this.isWelcome = false
        this.isRecordingCompliance = false
        this.isPatientInfo = false
        this.isWaiting = false
        this.yourTurnHasCome = false
        this.specialtySelected = false

        this.closePatientInfoMenu()
    }
}
