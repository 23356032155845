<app-header></app-header>
<section class="pt-5">
	<div class="wrapper-selects">
		<ul>
			<li
				*ngFor="let item of listItems; index as i"
				[class.active]="i == current"
				(click)="setCurrent(i)"
			>
				<figure>
					<img
						[src]="
							'/assets/images/config' +
							(i == current ? item.path + '-active' : item.path) +
							'.svg'
						"
						[alt]="item.title"
						[title]="item.title"
					/>
				</figure>
				{{ item.title }}
			</li>
		</ul>
	</div>
	<div class="wrapper-content">
		<app-testing-apis *ngIf="current == 0"></app-testing-apis>
		<app-testing-input-audio *ngIf="current == 1"></app-testing-input-audio>
		<app-testing-input-video *ngIf="current == 2"></app-testing-input-video>
		<app-testing-output-audio
			*ngIf="current == 3"
		></app-testing-output-audio>
	</div>
</section>
<div class="wrapper-button">
	<button class="btn" *ngIf="current < 3" (click)="nextStepSistemCheck()">
		AVANÇAR
	</button>
	<a
		class="btn"
		routerLink="/qr-code"
		*ngIf="current >= 3"
		(click)="nextStepSistemCheck()"
	>
		CONCLUIR
	</a>
</div>
