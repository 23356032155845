import { QueueComponent } from './pages/queue/queue.component'
import { ClosureComponent } from './pages/closure/closure.component'
import { StatusComponent } from './pages/status/status.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guard/auth.guard'
import { NoAuthGuard } from './guard/no-auth.guard'
import { LoginComponent } from './pages/login/login.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { AppointmentComponent } from './pages/appointment/appointment.component'
import { InitQrCodeComponent } from './pages/init-qr-code/init-qr-code.component'
import { PanelComponent } from './pages/panel/panel.component'
import { PageOnboardComponent } from './pages/onboard/page-onboard.component'
import { FaqComponent } from './pages/faq/faq.component'
import { ConfigComponent } from './pages/config/config.component'
import { SuccessConexaComponent } from './pages/success-conexa/success-conexa.component'
import { VisualAcuityComponent } from './components/exams/visual-acuity/visual-acuity.component'

const roles = ['cabin', 'suitcase']

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full',
	},
	{
		path: 'qr-code',
		component: InitQrCodeComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'status',
		component: StatusComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'onboard',
		component: PageOnboardComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'faq',
		component: FaqComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'painel',
		component: PanelComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'fim-consulta',
		component: ClosureComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'queue',
		component: QueueComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'consulta',
		component: AppointmentComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'checagem-do-sistema',
		component: ConfigComponent,
		canActivate: [AuthGuard],
		data: {
			roles: roles,
		},
	},
	{
		path: 'sucesso-conexa',
		component: SuccessConexaComponent,
		data: { title: 'Sucesso Conexa' },
	},
	{
		path: '404',
		component: NotFoundComponent,
		data: { title: 'Página não encontrada' },
	},
	{
		path: '**',
		redirectTo: '/404',
		data: { title: 'Página não encontrada' },
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
