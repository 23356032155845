<div class="wrapper__queue">
	<button type="button" class="close-faq" (click)="backToQrCode()"></button>
	<app-tabs>
		<app-tab tabTitle="Realocação de pacientes">
			<app-table-list
				*ngIf="listBody.length"
				[listHeader]="listHeaderPatientReallocation"
				[listBody]="listBody"
			>
				<ng-template let-item #body>
					<td width="160" class="text-left">
						{{ helpers.getDateFormatted(item.createdAt, true) }}
					</td>
					<td class="text-left">
						{{ item.patient.name }} {{ item.patient.lastName }}
					</td>
					<td width="160">
						{{ helpers.addMaskCpf(item.patient.documentCpf || '') }}
					</td>
					<td>
						<button class="btn" (click)="relocate(item)">
							Realocar
						</button>
					</td>
				</ng-template>
			</app-table-list>
			<div class="wrapper-info" *ngIf="!listBody.length">
				<figure>
					<img
						src="/assets/images/empty.svg"
						alt="Nenhum paciente encontrado"
						title="Nenhum paciente encontrado"
					/>
				</figure>
				<p>Nenhum paciente encontrado</p>
			</div>
		</app-tab>
	</app-tabs>
</div>
