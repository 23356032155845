import { ActionLoadingTypes } from './loading.action'
import { ActionModel } from '../action'
import {Loading} from "../../models/loading";

const initialState: Loading = {
    loading: false,
    loadingProgress: false
}

export function loadingReducer(state: Loading = initialState , action: ActionModel) {
	switch (action.type) {
		case ActionLoadingTypes.ShowLoading: {
			state = {
                ...state,
                loading: true
            }
			return state
		}

        case ActionLoadingTypes.ShowLoadingProgress: {
            state = {
                ...state,
                loadingProgress: true
            }
            return state
        }

		case ActionLoadingTypes.HiddenLoading: {
            state = {
                loading: false,
                loadingProgress: false
            }
			return state
		}

		default:
			return state
	}
}
