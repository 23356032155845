import {Component, OnInit} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {v4 as uuidv4} from 'uuid';
import {User} from "../../models/user";
import {Observable, Subscription} from "rxjs";
import {AppState} from "../../state/app-state";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {PanelService} from "../../services/panel/panel.service";
import {ToastrService} from "ngx-toastr";
import {SocketIoService} from "../../services/socket-io/socket-io.service";
import {StatsService} from "../../services/stats/stats.service";

@Component({
    selector: 'app-init-qr-code',
    templateUrl: './init-qr-code.component.html',
    styleUrls: ['./init-qr-code.component.scss']
})
export class InitQrCodeComponent implements OnInit {
    private subscriptions: Subscription[] = []
    private user$?: Observable<User>
    user?: User

    private uuid: string = ''
    qrCode: string
    showQrCode: boolean = false

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private panelService: PanelService,
        private clipboard: Clipboard,
        private toast: ToastrService,
        private socketIoService: SocketIoService,
        private statsService: StatsService,
    ) {
        this.user$ = this.store.select('user')
        this.subscriptions.push(
            this.user$.subscribe((state) => {
                this.user = state
            })
        )

        this.panelService.getSession()
        this.qrCode = this.generateQrCode()
    }

    ngOnInit(): void {
        this.statsService.stats('init_qrcode', 'start')
        this.generateQrCode()
        this.socketIoService.watchEventsSocket('qrCodeRead', () => {
            this.panelService.goToPanelExams()
        })
    }

    ngOnDestroy() {
        this.statsService.stats('init_qrcode', 'end')
    }

    generateQrCode(): string {
        this.uuid = this.panelService.getQrCodeLocale()

        if (!this.uuid) {
            this.uuid = uuidv4();
        }

        const qrCodeData = {
            session_id: this.uuid,
            cabin_id: this.user?.id,
            client_id: this.user?.client?.id,
        };

        this.panelService.setQrCodeLocale(this.uuid)

        return JSON.stringify(qrCodeData)
    }

    toggleAndCopyQrcode(): void {
        this.showQrCode = !this.showQrCode

        if (this.showQrCode) {
            this.clipboard.copy(this.qrCode)
            this.toast.show('QrCode copiado!')
        }
    }
}
