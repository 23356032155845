<button
	*ngIf="isButton"
	type="button"
	[disabled]="disabled"
	(click)="fnHandler()"
	[class.active]="active"
	#element
>
	<p (click)="selectedItem()"><span></span> {{ title }}</p>
	<div class="sub-items">
		<ng-content></ng-content>
	</div>
</button>
<a *ngIf="!isButton" [routerLink]="link" [class.active]="active" #element>
	<span></span> {{ title }}
</a>
