import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {HelpersService} from '../../../services/helpers/helpers.service'
import {PayloadSendExamsBody} from "../../../models/requests";
import {ExamsService} from "../../../services/exams/exams.service";
import {ToastrService} from "ngx-toastr";
import {Exam, ExamFile, PayloadEditExam} from "../../../models/exam";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/app-state";
import {HiddenLoading, ShowLoadingProgress} from "../../../state/loading/loading.action";

@Component({
    selector: 'app-imaging-exams',
    templateUrl: './imaging-exams.component.html',
    styleUrls: ['./imaging-exams.component.scss'],
})
export class ImagingExamsComponent implements OnInit {
    @Input() exam?: Exam
    @Output() public onCompleteCapture: EventEmitter<any> = new EventEmitter()

    @ViewChild('originalVideo', {static: false})
    public _videoRef?: ElementRef
    private video?: HTMLVideoElement

    @ViewChild('canvas', {static: false})
    public _canvasRef?: ElementRef
    private canvas?: HTMLCanvasElement

    showConfig: boolean = false
    optionVideoSelected: string = 'default'
    optionsVideo: any[] = []

    fullScreen: boolean = false

    currentExam: any

    images: ExamFile[] = []
    imagePreview: string = ''

    constructor(
        private helpers: HelpersService,
        private examsService: ExamsService,
        private toast: ToastrService,
        private store: Store<AppState>,
    ) {
    }

    ngOnInit(): void {
        this.initCurrentExam()
        setTimeout(()=> {
            this.getExams()
        }, 10)
    }

    public ngAfterViewInit() {
        this.video = this._videoRef?.nativeElement
        this.getAccessDevices()
    }

    initCurrentExam() {
        if (this.exam?.children?.length) {
            this.currentExam = this.exam?.children[0]
        } else {
            this.currentExam = this.exam
        }
    }

    selectExam(item: any) {
        this.currentExam = item
    }

    getExams() {
        const self = this
        const slugs: string[] = []
        if (self.exam?.children?.length) {
            self.exam?.children.forEach((item: any) => {
                slugs.push(item.slug)
            })
        } else {
            slugs.push(self.exam?.slug || '')
        }
        self.examsService.getExams(slugs, (items: ExamFile[]) => {
            self.images = items
        })
    }

    videoStream(stream: any, video?: HTMLVideoElement) {
        if (video) {
            video.srcObject = stream
            video.play()
        }
    }

    getAccessDevices() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({video: true})
                .then((stream) => {
                    this.videoStream(stream, this.video)
                })
        }
    }

    snapPictures() {
        this.canvas = this._canvasRef?.nativeElement
        if (this.canvas) {
            this.canvas.width = 1920
            this.canvas.height = 1080
            const context = this.canvas.getContext('2d')
            //@ts-ignore
            context.drawImage(this.video, 0, 0, 1920, 1080)
            this.imagePreview = this.canvas.toDataURL('image/png')
            const file = this.helpers.dataURLtoFile(
                this.imagePreview,
                'file.png'
            )
            this.sendFile(file)
        }
    }

    sendFile(file: File) {
        const body: PayloadSendExamsBody = {
            file,
            examName: this.currentExam.name,
            examSlug: this.currentExam.slug,
            callback: () => {
                this.getExams()
            }
        }
        this.onCompleteCapture.emit(body)
    }

    openModalExcludedExam(item: any) {
        this.examsService.modalExcludedExam(item, () => {
            this.getExams()
        })
    }

    editExam(body: PayloadEditExam, id: string) {
        const self = this
        self.store.dispatch(ShowLoadingProgress())
        this.examsService.editExam(id, body.value, {
            fnSuccess() {
                self.toast.success('Text do exame editado com sucesso!')
                self.getExams()
            },
            fnError(error) {
                self.toast.error('Ocorreu um problema ao editar o texto do exame', 'Tente novamente!')
            },
            fnFinalized() {
                self.store.dispatch(HiddenLoading())
            }
        })
    }

    getDevices() {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            this.optionsVideo = []
            this.helpers.setDevicesInList(mediaDevices, this.optionsVideo, 'videoinput')
        })
    }

    async changeVideoInput() {
        if (this.optionVideoSelected !== '') {
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        deviceId: {exact: this.optionVideoSelected},
                    },
                })
                .then((stream) => {
                    this.videoStream(stream, this.video)
                })
            this.closeModalConfiguration()
        }
    }

    openModalConfiguration(): void {
        this.showConfig = !this.showConfig

        if (this.showConfig) {
            this.getDevices()
        }
    }

    closeModalConfiguration(): void {
        this.showConfig = false
    }

    toggleFullScreen() {
        this.fullScreen = !this.fullScreen
    }
}
