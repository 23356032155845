import { Component, OnInit } from '@angular/core'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	listFaq: any[] = []
	constructor(private statsService: StatsService) {
		this.listFaq = [
			{
				title: 'Como saber se a cabine está conectada com o servidor e qual versão do sistema atual?',
				description:
					'No rodapé esquerdo abaixo dos steps da consulta existem 2 informações: <br /> 1 - Indica se a cabine está online com o servidor ou não; <br /> 2 - Versão atual do sistema.',
				video: '../../../assets/videos/status-e-versao.mp4',
			},
			{
				title: 'Como realocar um paciente na consulta?',
				description:
					'1 - Clique no menu principal e selecione a opção "Realocar paciente"; <br/> 2 - Na página de realocação de paciente, selecione o paciente na listagem e clique no botão realocar; <br /> 3 - Confirme a realocação.',
				video: '../../../assets/videos/realocar-paciente.mp4',
			},
			{
				title: 'Como cancelar uma consulta?',
				description:
					'Clique no botão circular flutuante ao topo à direita e selecione "Cancelar atendimento"',
				video: '../../../assets/videos/cancelar-consulta.mp4',
			},
			{
				title: 'Onde consultar se periféricos conectados e os serviços do sistema estão funcionais?',
				description:
					'Clique no menu principal e selecione a opção "Status do sistema"',
				video: '../../../assets/videos/status-do-sistema.mp4',
			},
			{
				title: 'Como faço para sair da minha conta atual na aplicação?',
				description:
					'Clique no menu principal e selecione a opção "Sair da conta"',
				video: '../../../assets/videos/sair-da-conta.mp4',
			},
		]
	}

	ngOnInit(): void {
		this.statsService.stats('init_faq', 'start')
	}

	ngOnDestroy() {
		this.statsService.stats('init_faq', 'start')
	}
}
