<header>
	<div class="wrapper-menu">
		<div class="logo" (click)="toggleMenu()">
			<figure>
				<img
					src="assets/images/logo-small-2.svg"
					alt="H.ai"
					title="H.ai"
				/>
			</figure>
			<button [class.menu-active]="showMenu">
				<img
					src="assets/images/arrow-down-blue.png"
					alt="H.ai"
					title="H.ai"
				/>
			</button>
		</div>

		<div class="float-button">
			<button
				[class.active]="isShowFloatMenu"
				(click)="toggleFloatButton()"
			>
				<img src="/assets/images/icons/plus.png" alt="+" title="+" />
			</button>
		</div>
	</div>

	<div class="wrapper-dropdown" [class.active]="showMenu">
		<a *ngFor="let menu of headerMenuList" [routerLink]="'/' + menu.path">
			{{ menu.title }}
		</a>
		<button class="mt-5" (click)="logout()" type="button">
			Sair da conta
		</button>
	</div>

	<div class="wrapper-dropdown right" [class.active]="isShowFloatMenu">
		<button
			*ngFor="let menu of headerFloatButtonList"
			(click)="menu.fn()"
			[title]="menu.title"
		>
			{{ menu.title }}
		</button>
	</div>
</header>
