import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from '../../models/callbacks'

@Injectable({
	providedIn: 'root',
})
export class VersionService {
	constructor(private http: HttpClient) {}

	getVersion(callback: Callbacks): void {
		this.http
			.get<any>(`assets/version.txt`, { responseType: 'text' as 'json' })
			.subscribe(
				(data) => {
					callback.fnSuccess(data)
				},
				(err) => {
					callback.fnError(err)
				}
			)
	}
}
