import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import {
    connect,
    createLocalVideoTrack,
    createLocalAudioTrack,
    Room,
    LocalAudioTrack,
    LocalVideoTrack,
} from 'twilio-video'
import {
    manageTracksForRemoteParticipant,
    onParticipantConnected,
    onParticipantDisconnected,
} from '../../lib/twilio'
import {ToastrService} from 'ngx-toastr'
import {HelpersService} from "../../services/helpers/helpers.service";

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.component.html',
    styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements OnInit {
    @Input() roomName: string = ''
    @Input() token: string = ''
    @Input() config?: boolean
    @Output() emmitEventRecord: EventEmitter<any> = new EventEmitter()
    @Output() emmitEventReleasePatient: EventEmitter<any> = new EventEmitter()

    mute?: boolean = false
    webcam?: boolean = true
    isFullScreen?: boolean = false
    loading: boolean = true

    minimizeItem: boolean = false
    minify: boolean = false
    participants: any[] = []
    room?: Room

    optionsAudioInput: any[] = []
    optionsAudioOutput: any[] = []
    optionsVideoInput: any[] = []

    optionAudioInputSelected: string = 'default'
    optionAudioOutputSelected: string = 'default'
    optionVideoInputSelected: string = 'default'

    localVideoTrack?: LocalVideoTrack

    isParticipant: boolean = false

    @ViewChild('localMediaPatientContainer', {static: false})
    public _localMediaPatientContainer?: ElementRef
    private videoPatientContainer?: ElementRef

    @ViewChild('localMediaDoctorContainer', {static: false})
    public _localMediaDoctorContainer?: ElementRef
    private videoDoctorContainer?: ElementRef

    constructor(private toast: ToastrService, private helpers: HelpersService) {
    }

    async ngOnInit(): Promise<void> {
        await this.startedAppointment()
    }

    ngOnDestroy(): void {
        this.disconnectRoom()
    }

    minimize(): void {
        this.minimizeItem = !this.minimizeItem
        this.minify = !this.minify
    }

    toggleFullscreen(): void {
        this.isFullScreen = !this.isFullScreen
    }

    toggleMute(): void {
        this.mute = !this.mute
        this.room?.localParticipant.audioTracks.forEach((audio) => {
            this.mute ? audio.track.disable() : audio.track.enable()
        })
    }

    toggleWebcam(): void {
        this.webcam = !this.webcam

        if (this.webcam) {
            this.enableWebcam()
        } else {
            this.disableWebcam()
        }
    }

    enableWebcam(): void {
        this.room?.localParticipant.videoTracks.forEach((video) => {
            video.track.enable()
        })
    }

    disableWebcam(stopLocalWebcam?: boolean): void {
        this.room?.localParticipant.videoTracks.forEach((video) => {
            video.track.disable()
            if (stopLocalWebcam) {
                video.track.stop()
            }
        })
        if (stopLocalWebcam) {
            this.localVideoTrack?.stop()
        }
    }

    getDevices() {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            this.optionsAudioInput = []
            this.helpers.setDevicesInList(mediaDevices, this.optionsAudioInput, 'audioinput')

            this.optionsAudioOutput = []
            this.helpers.setDevicesInList(
                mediaDevices,
                this.optionsAudioOutput,
                'audiooutput'
            )

            this.optionsVideoInput = []
            this.helpers.setDevicesInList(mediaDevices, this.optionsVideoInput, 'videoinput')

            this.optionVideoInputSelected = this.optionsVideoInput.length
                ? this.optionsVideoInput[0].value
                : 'default'
        })
    }

    async changeAudioInput() {
        if (this.optionAudioInputSelected !== '') {
            const localDevice = await createLocalAudioTrack({
                deviceId: {exact: this.optionAudioInputSelected},
            })

            const tracks: LocalAudioTrack[] = []
            this.room?.localParticipant.audioTracks.forEach((audio) => {
                tracks.push(audio.track)
            })

            await this.room?.localParticipant.unpublishTracks(tracks)
            await this.room?.localParticipant.publishTrack(localDevice)
        }
    }

    async changeAudioOutput() {
        this.isParticipant = false
        this.room?.participants.forEach((participant) => {
            this.isParticipant = true
        })

        if (this.optionAudioOutputSelected !== '' && this.isParticipant) {
            const localDevice = this.optionAudioOutputSelected
            const audioElement =
                this.videoPatientContainer?.nativeElement.querySelector('audio')
            audioElement.setSinkId(localDevice)
        }
    }

    async changeVideoInput() {
        if (this.optionVideoInputSelected !== '') {
            this.localVideoTrack = await createLocalVideoTrack({
                deviceId: {exact: this.optionVideoInputSelected},
            })

            const tracks: LocalVideoTrack[] = []
            this.room?.localParticipant.videoTracks.forEach((video) => {
                tracks.push(video.track)
            })

            await this.room?.localParticipant.unpublishTracks(tracks)
            await this.room?.localParticipant.publishTrack(this.localVideoTrack)

            this.localVideoTrack.attach(
                this.videoDoctorContainer?.nativeElement
            )

            this.webcam ? this.enableWebcam() : this.disableWebcam()
        }
    }

    releasePatient() {
        this.emmitEventReleasePatient.emit('releasePatient')
    }

    openModalConfiguration(): void {
        this.config = !this.config
    }

    private async startedAppointment(): Promise<void> {
        this.localVideoTrack = await createLocalVideoTrack()

        this.videoDoctorContainer = this._localMediaDoctorContainer
        if (this.videoDoctorContainer && this.token) {
            this.localVideoTrack.attach(
                this.videoDoctorContainer?.nativeElement
            )
            await this.setup()
            this.getDevices()
        }
    }

    async setup(): Promise<void> {
        try {
            this.room = await connect(this.token, {
                name: this.roomName,
                video: this.webcam,
                audio: !this.mute,
            })

            this.videoPatientContainer = this._localMediaPatientContainer
            this.room?.participants.forEach((participant) => {
                this.isParticipant = true
                manageTracksForRemoteParticipant(
                    participant,
                    this.videoPatientContainer
                )
            })

            this.room?.on('participantConnected', (participant) => {
                if (participant) {
                    this.isParticipant = true
                }
                onParticipantConnected(participant, this.videoPatientContainer)
            })
            this.room?.on('participantDisconnected', onParticipantDisconnected)
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                this.toast.error('Câmera ou microfone bloqueados')
                console.warn('permission')
                throw error.message
            } else if (error.message === 'Requested device not found') {
                this.toast.error('Câmera ou microfone não conectados')
                console.warn('notFound')
                throw error.message
            } else if (error.message === 'Signaling connection error') {
                this.toast.error('Problemas de conexão')
                console.warn('connectionProblem')
                throw error.message
            } else if (
                error.message === 'Could not start video source' ||
                error.message === 'Failed to allocate videosource'
            ) {
                this.toast.error('Não foi possível iniciar o vídeo')
                console.warn('startVideo')
                throw error.message
            } else {
                this.toast.error('Ocorreu um problema')
                console.warn('unknownProblem')
                throw error.message
            }
        } finally {
            this.loading = false
        }
    }

    disconnectRoom(): void {
        this.disableWebcam(true)
        this.room?.localParticipant.audioTracks.forEach((audio) => {
            audio.track.disable()
            audio.track.mediaStreamTrack.stop()
            audio.track.stop()
        })
        this.room?.disconnect()
    }
}
