import {Component, OnInit} from '@angular/core'
import {AppointmentsService} from '../../services/appointments/appointments.service'
import {Observable, Subscription} from 'rxjs'
import {Store} from '@ngrx/store'
import {AppState} from '../../state/app-state'
import {Patient} from '../../models/patient'
import {ToastrService} from 'ngx-toastr'
import {Router} from '@angular/router'
import {StatsService} from '../../services/stats/stats.service'
import {Exam} from '../../models/exam'
import {HelpersService} from '../../services/helpers/helpers.service'
import {Session} from '../../models/session'
import {PanelService} from '../../services/panel/panel.service'
import {ExamsService} from '../../services/exams/exams.service'
import {SocketIoService} from '../../services/socket-io/socket-io.service'
import {Client} from "../../models/client";
import {AuthService} from "../../services/auth/auth.service";

@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
    private subscriptions: Subscription[] = []
    private patient$?: Observable<Patient>
    patient?: Patient

    full: boolean = false
    showExams: boolean = false

    session: Session | null = null

    client?: Client

    token: string = ''
    nameDoctor: string = ''

    alreadyInitialized?: boolean

    examIsCabin: boolean = false
    exam?: Exam

    _reload: boolean = true

    constructor(
        private store: Store<AppState>,
        private appointmentService: AppointmentsService,
        private toast: ToastrService,
        private router: Router,
        private statsService: StatsService,
        private helpers: HelpersService,
        private panelService: PanelService,
        private authService: AuthService,
        public examsService: ExamsService,
        private socketIoService: SocketIoService
    ) {
        this.patient$ = this.store.select('patient')
        this.subscriptions.push(
            this.patient$.subscribe((state) => {
                this.patient = state
            })
        )

        this.getSession()
    }

    ngOnInit(): void {
        if (!this.session) {
            this.pageError()
        } else {
            this.statsService.stats(
                'init_appointment_cabin',
                'start',
                this.session.id
            )

            this.getAppointment()
            this.watchEventsSocket()
            this.socketIoService.emitEventSocket(
                'updatePatientList',
                null,
                'panel_cmd'
            )
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe()
        })

        this.statsService.stats(
            'init_appointment_cabin',
            'end',
            this.session?.id
        )

        this.session = null
    }

    private reload() {
        setTimeout(() => (this._reload = false))
        setTimeout(() => (this._reload = true))
    }

    watchEventsSocket() {
        this.socketIoService.watchEventsSocket(
            'appointmentStarted',
            () => {
                this.getAppointment()
            }
        )
        this.socketIoService.watchEventsSocket('appointmentFinalized', () => {
            this.finalizedAppointment()
        })
        this.socketIoService.watchEventsSocket('appointmentCanceled', () => {
            this.toast.warning('Consulta cancelado!')
            this.panelService.cancelSession(true)
        })
        this.socketIoService.watchEventsSocket(
            'examSelected',
            (response: any) => {
                const {data} = response.content
                this.setExam(data)
            }
        )
    }

    getAppointment(withToken: boolean = true, callback?: Function) {
        const self = this
        if (self.session) {
            self.appointmentService.getAppointment(self.session.id, {
                fnSuccess(response?: any) {
                    const {data} = response

                    console.log(data)

                    self.panelService.setSession(data)

                    self.getSession()
                    self.isFinalizedAppointment()

                    if (withToken) {
                        self.getTokenTwilio()
                    }
                },
                fnError(error) {
                    self.toast.error('Service error')
                },
                fnFinalized() {
                    if (callback) callback()
                },
            })
        } else {
            self.pageError()
        }
    }

    getSession() {
        this.session = this.panelService.getSessionLocale()
        const user = this.authService.getUserLocale()
        this.client = this.authService.getClient(user.clients)
    }

    getTokenTwilio() {
        const self = this

        if (self.session) {
            self.appointmentService.getTokenVideoCall(self.session.hash, {
                fnSuccess(data?: any) {
                    const {token} = data
                    self.token = token
                },
                fnError(error) {
                    self.toast.error('Service error')
                },
            })
        } else {
            this.pageError()
        }
    }

    setExam(exam?: any) {
        this.reload()

        if (exam) {
            exam.children = exam?.children?.map((item: any) => {
                return {
                    ...this.helpers.converterSnakeToCamelCase(item),
                }
            })

            this.exam = {
                ...this.helpers.converterSnakeToCamelCase(exam),
            }

            this.examIsCabin = this.exam?.capturedBy === 'cabin'

            if (this.examIsCabin) {
                this.openPanelExams()
                this.socketIoService.emitEventSocket('examInitialized', {
                    data: this.exam,
                })
            }
        }
    }

    finishExam() {
        if (this.examIsCabin) {
            this.socketIoService.emitEventSocket('examFinalized', {
                data: this.exam,
            })
        }
    }

    finalizedAppointment(): void {
        this.getAppointment(false, () => {
            this.isFinalizedAppointment()
        })
    }

    isFinalizedAppointment() {
        if (
            this.session?.status === 'finished_video' ||
            this.session?.status === 'finalized' ||
            this.session?.status === 'expired' ||
            this.session?.status === 'canceled'
        ) {
            this.panelService.cancelSession()
            this.router.navigate(['/fim-consulta'])
        }
    }

    openPanelExams() {
        this.showExams = true
    }

    togglePanelExams() {
        this.showExams = !this.showExams

        if (!this.showExams) {
            this.finishExam()
        }
    }

    layerExams() {
        this.togglePanelExams()
    }

    pageError() {
        this.toast.error('Não existe consulta no momento!')
        this.router.navigate(['/qr-code'])
    }
}
