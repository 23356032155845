import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {StatsService} from '../../services/stats/stats.service'

@Component({
    selector: 'app-closure',
    templateUrl: './closure.component.html',
    styleUrls: ['./closure.component.scss'],
})
export class ClosureComponent implements OnInit {
    constructor(private router: Router, private statsService: StatsService) {
    }

    ngOnInit(): void {
        this.statsService.stats('init_closure', 'start')
    }

    ngOnDestroy() {
        this.statsService.stats('init_closure', 'end')
    }

    finish() {
        // this.router.navigate(['/qr-code'])
        //@ts-ignore
        window.location = '/qr-code'
    }
}
