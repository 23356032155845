import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AuthService } from '../../services/auth/auth.service'
import { AppState } from '../../state/app-state'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ShowLoading, HiddenLoading } from '../../state/loading/loading.action'

import { ToastrService } from 'ngx-toastr'
import { StatsService } from '../../services/stats/stats.service'
import { HelpersService } from '../../services/helpers/helpers.service'
import { ModalActiveSectionComponent } from '../../components/modals/modal-active-section/modal-active-section.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	form: FormGroup

	session = {
		title: '',
		date: '',
		browser: '',
		machine: '',
	}

	constructor(
		private store: Store<AppState>,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private toast: ToastrService,
		private statsService: StatsService,
		public helpers: HelpersService,
		private modalDefault: NgbModal
	) {
		this.form = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email]],
			password: [null, Validators.required],
		})
	}

	ngOnInit(): void {}

	hasSectionValidOtherPlatform() {
		this.store.dispatch(HiddenLoading())
		const modalRef = this.modalDefault.open(ModalActiveSectionComponent)
		modalRef.componentInstance.title = 'Foi detectado outra seção válida'
		modalRef.componentInstance.desc =
			'Ao continuar você será deslogado da outra seção automaticamente'
		modalRef.componentInstance.sectionData = this.session
		modalRef.componentInstance.callbackConfirmation = () => {
			this.onSubmit()
		}
	}

	onSubmitSessionUser(): void {
		const self = this
		if (self.form.valid) {
			self.store.dispatch(ShowLoading())
			const { email, password } = self.form.value

			this.authService.loginHasSessionUser({
				payload: {
					email,
					password,
				},
				fnSuccess(data) {
					if (data.active_session) {
						const agent = self.helpers.parseUA(data.user_agent)
						self.session.machine =
							agent.Mozilla && agent.Mozilla.properties
								? agent.Mozilla.properties
								: agent.Mozilla && agent.Mozilla.Windows
								? agent.Mozilla.Windows
								: 'Other'

						self.session.browser = self.getBrowser(data.user_agent)
						self.session.date = self.helpers.converterTsInDate(
							data.date
						)
						self.hasSectionValidOtherPlatform()
					} else {
						self.onSubmit()
					}
				},
				fnError(responseError) {
					console.warn(responseError)
					self.store.dispatch(HiddenLoading())
					self.toast.error(
						responseError.error.detail ||
							'Login ou senha incorreto',
						'Erro!'
					)
				},
			})
		} else {
			self.toast.error('Login inválido!')
		}
	}

	onSubmit(): void {
		const self = this
		if (self.form.valid) {
			self.statsService.stats('login_cabin', 'start')
			self.store.dispatch(ShowLoading())
			const { email, password } = self.form.value

			this.authService.login({
				payload: {
					email,
					password,
				},
				fnSuccess() {
					self.form.reset()
					self.authService.redirectAuth()
					self.statsService.stats('login_cabin', 'end')
				},
				fnError(responseError) {
					if (
						responseError.status >= 400 &&
						responseError.status < 500
					) {
						self.toast.error('Login ou senha incorreto', 'Erro!')
					} else {
						self.toast.error(
							`Status: ${responseError.status}`,
							'Server Error!'
						)
					}
					console.warn(responseError)
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		}
	}

	getBrowser(a: string) {
		return a.includes('Firefox/')
			? 'Firefox'
			: a.includes('Edg/')
			? 'Edge'
			: a.includes('Brave')
			? 'Brave'
			: a.includes('Chrome/')
			? 'Chrome'
			: a.includes('Safari/')
			? 'Safari'
			: a.includes('OPR/')
			? 'opera'
			: 'Other'
	}
}
