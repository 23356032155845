<div class="wrapper-audio-recording">
	<div>
		<h5 class="mb-2">{{ currentExam?.name }}</h5>
		<div class="select-exam" *ngIf="exam?.children?.length">
			<app-select-exam
				*ngFor="let item of exam?.children"
				[active]="item.slug === currentExam?.slug"
				[image]="item.thumbWeb"
				[title]="item.name"
				(click)="selectExam(item)"
			></app-select-exam>
		</div>
		<div class="wrapper auscultation">
			<canvas #audioCanvas></canvas>
			<div class="wrapper-button-record">
				<button
					type="button"
					(click)="
						!recording
							? startRecordingAudio()
							: stopRecordingAudio()
					"
				>
					<img
						draggable="false"
						[src]="
							!recording
								? '/assets/images/controls/recorder.svg'
								: '/assets/images/controls/close-recorder.svg'
						"
						[alt]="!recording ? 'Gravar' : 'Gravando'"
						[title]="!recording ? 'Gravar' : 'Gravando'"
					/>
				</button>
				<span>{{ !recording ? 'Gravar' : 'Gravando' }}</span>
			</div>
			<div class="wrapper-config">
				<button type="button" (click)="openModalConfiguration()">
					<img
						src="assets/images/controls/config.png"
						alt="Configuração"
						title="Configuração"
					/>
				</button>
			</div>
			<div class="content-config" *ngIf="showConfig">
				<form>
					<div class="wrapper-select">
						<div>
							<figure>
								<img
									src="assets/images/icons/mic.svg"
									alt="Microfone"
									title="Microfone"
								/>
							</figure>
							<div>
								<label for="mic">Microfone</label>
								<select
									[(ngModel)]="optionAudioInputSelected"
									(change)="changeAudioInput()"
									name="mic"
									id="mic"
								>
									<option
										*ngFor="let item of optionsAudioInput"
										[ngValue]="item.value"
									>
										{{ item.label }}
									</option>
								</select>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<app-title title="Dados coletados" [subTitle]="exam?.name">
		<div class="custom-scroll">
			<ul class="custom-scroll">
				<li *ngFor="let item of audios">
					{{ item.examValue || 'Ausculta' }}
					<button type="button" (click)="openModalExcludedExam(item)">
						<img
							src="/assets/images/trash.svg"
							alt="Excluir"
							title="Excluir"
						/>
					</button>
				</li>
			</ul>
		</div>
	</app-title>
</div>
