import { Action } from '@ngrx/store'
import {StepsAppointment} from "../../models/stepsAppointment";

export enum ActionStepsAppointmentTypes {
	Add = '@STEPS_APPOINTMENT/ADD',
	Clear = '@STEPS_APPOINTMENT/CLEAR',
}

export const AddStepsAppointment = (value: StepsAppointment[]) => {
	return <Action>{ type: ActionStepsAppointmentTypes.Add, payload: value }
}

export const ClearStepsAppointment = () => {
	return <Action>{ type: ActionStepsAppointmentTypes.Clear, payload: null }
}
