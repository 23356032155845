import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanDeactivate,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth/auth.service'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard
	implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad
{
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		let url: string = state.url
		return this.checkUserLogin(next, url)
	}
	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.canActivate(next, state)
	}
	canDeactivate(
		component: unknown,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return true
	}
	canLoad(
		route: Route,
		segments: UrlSegment[]
	): Observable<boolean> | Promise<boolean> | boolean {
		return true
	}

	checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
		if (this.authService.isLoggedIn()) {
            let hasPermission = false
			const userRoles = this.authService.getRole()
            this.authService.setUserState()

            userRoles.forEach(role => {
                if(route.data.roles.includes(role)) {
                    hasPermission = true
                }
            })

			if (!hasPermission) {
				this.router.navigate(['/login'])
				return false
			}
			return true
		}

		this.router.navigate(['/login'])
		return false
	}
}
