<div class="card-exam">
	<button title="Fechar" class="close" (click)="removeImageList()"></button>
	<app-image [load]="true" [title]="title" [path]="blob"></app-image>
	<div class="wrapper-title">
		<p>{{ title }}</p>
		<label *ngIf="!isEditing" [for]="'desc-' + id" (click)="editText()">
			<img src="/assets/images/edit.svg" alt="Editar" title="Editar" />
		</label>
		<label
			class="btn-save"
			*ngIf="isEditing"
			[for]="'desc-' + id"
			(click)="saveText()"
		>
			<img
				src="/assets/images/icons/send.svg"
				alt="Atualizar"
				title="Atualizar"
			/>
		</label>
	</div>
	<div class="wrapper-desc">
		<textarea
			[id]="'desc-' + id"
			[(ngModel)]="value"
			(click)="editText()"
		></textarea>
	</div>
</div>
