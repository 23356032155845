<div class="exams">
    <div>
        <h5 class="mb-2">{{currentExam?.name}}</h5>
        <div class="select-exam mb-3" *ngIf="exam?.children?.length">
            <app-select-exam
                *ngFor="let item of exam?.children"
                [active]="item.slug === currentExam?.slug"
                [image]="item.thumbWeb"
                [title]="item.name"
                (click)="selectExam(item)"
            ></app-select-exam>
        </div>
        <div class="select-exam mb-3" *ngIf="!exam?.children?.length">
            <app-select-exam
                [active]="true"
                [image]="exam?.thumbWeb"
                [title]="exam?.name"
            ></app-select-exam>
        </div>
        <div class="wrapper wrapper-video" [class.fullScreen]="fullScreen">
            <canvas #canvas></canvas>
            <video #originalVideo autoplay></video>
            <button class="hover" type="button" (click)="snapPictures()">
                <img
                    src="/assets/images/camera.svg"
                    alt="Clique na tela para fazer uma captura"
                    title="Clique na tela para fazer uma captura"
                />
                Clique na tela para fazer uma captura
            </button>
            <div class="wrapper-config">
                <button type="button" (click)="openModalConfiguration()">
                    <img
                        src="assets/images/controls/config.svg"
                        alt="Configuração"
                        title="Configuração"
                    />
                </button>
            </div>
            <div class="content-config" *ngIf="showConfig">
                <form>
                    <div class="wrapper-select">
                        <div>
                            <figure>
                                <img
                                    src="assets/images/icons/camera.svg"
                                    alt="Vídeo"
                                    title="Vídeo"
                                />
                            </figure>
                            <div>
                                <label for="video">Vídeo</label>
                                <select
                                    [(ngModel)]="optionVideoSelected"
                                    (change)="changeVideoInput()"
                                    name="video"
                                    id="video"
                                >
                                    <option
                                        *ngFor="let item of optionsVideo"
                                        [ngValue]="item.value"
                                    >
                                        {{ item.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="wrapper-config bottom">
                <button type="button" (click)="toggleFullScreen()">
                    <img
                        [src]="
							fullScreen
								? 'assets/images/controls/fullscreen-on.svg'
								: 'assets/images/controls/fullscreen.svg'
						"
                        alt="Fullscreen"
                        title="Fullscreen"
                    />
                </button>
            </div>
        </div>
    </div>
    <app-title title="Dados coletados" [subTitle]="exam?.name">
        <div class="grid custom-scroll">
            <app-card-exam
                *ngFor="let item of images"
                [id]="item.id"
                [title]="item.examValue || ''"
                [image]="item.fileUrlThumbnail || item.fileUrl"
                (emmitEventRemove)="openModalExcludedExam(item)"
                (emmitEventSave)="editExam($event, item.id)"
            ></app-card-exam>
        </div>
    </app-title>
</div>
