<div class="container pb-5">
	<div class="header">
		<div class="logo-content">
			<img src="assets/images/logo-blue.svg" alt="Logo da HAI" />
			<span>Status</span>
		</div>
		<button
			(click)="goToNext()"
			class="btn"
			[disabled]="isDisabledButton()"
		>
			AVANÇAR
		</button>
	</div>

	<div class="wrapper-content">
		<section class="status container">
			<div class="status__feedback">
				<app-onboard
					[title]="statusApi.title"
					[description]="statusApi.desc"
					[src]="statusApi.img"
					direction="reverse"
				></app-onboard>
			</div>
			<div class="status__current">
				<div class="status__current__info">
					<p>Status atual por serviço:</p>

					<div class="status__current__info__imgs">
						<app-status-image
							path="success"
							labelDanger="Sem divergências"
						></app-status-image>
						<app-status-image
							path="incidente"
							labelDanger="Incidente encontrado"
						></app-status-image>
						<app-status-image
							path="error"
							labelDanger="Problema encontrado"
						></app-status-image>
					</div>
				</div>
			</div>
			<div class="status__devices">
				<div>
					<app-status-image
						title="Microfone"
						[path]="optionsAudioInput.length ? 'success' : 'error'"
						labelDanger="Não existe nenhuma entrada de microfone conectada"
						[devices]="optionsAudioInput"
					></app-status-image>
					<app-status-image
						title="Áudio"
						[path]="optionsAudioOutput.length ? 'success' : 'error'"
						[devices]="optionsAudioOutput"
						labelDanger="Não existe nenhuma saída de áudio conectada"
					></app-status-image>
					<app-status-image
						title="Câmera"
						[path]="
							optionsVideoInput.length ? 'success' : 'incidente'
						"
						[devices]="optionsVideoInput"
						labelDanger="Não existe nenhuma câmera conectada"
					></app-status-image>
				</div>
				<div class="apis">
					<p class="title mb-2">Serviços</p>
					<div *ngFor="let item of listApis; index as i">
						<div class="status mb-2">
							<div>
								<p class="pl-2">- {{ item.api }}</p>
							</div>
							<figure>
								<img
									draggable="false"
									[src]="
										'/assets/images/status-' +
										(item.status != 'error'
											? 'success'
											: !item.required &&
											  item.status === 'error'
											? 'incidente'
											: 'error') +
										'.png'
									"
									[alt]="
										item.status != 'error'
											? 'Sucesso'
											: 'error'
									"
									[title]="
										item.status != 'error'
											? 'Sucesso'
											: 'error'
									"
								/>
							</figure>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
