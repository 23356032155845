import {ActionStepsAppointmentTypes} from './steps-appointment.action'
import {ActionModel} from '../action'
import {StepsAppointment} from "../../models/stepsAppointment";

const initialState: StepsAppointment[] = []

export function stepsAppointmentReducer(state: StepsAppointment[] = initialState, action: ActionModel) {
    switch (action.type) {
        case ActionStepsAppointmentTypes.Add: {
            state = [...action.payload]
            return state
        }

        case ActionStepsAppointmentTypes.Clear: {
            state = initialState
            return state
        }

        default:
            return state
    }
}
