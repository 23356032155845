<app-header></app-header>
<div class="wrapper-qrcode">
        <img
            draggable="false"
            src="assets/images/logo-blue.svg"
            alt="H.ai"
            title="H.ai"
            class="logo"
        />
		<qr-code
        class="qr-code"
        [value]="qrCode"
        [size]="300"
        (dblclick)="toggleAndCopyQrcode()"
		></qr-code>
		<p class="hash">Para iniciar faça a leitura do QR Code</p>
		<p *ngIf="showQrCode" class="text-qr-code">{{ qrCode }}</p>
	<app-status-login></app-status-login>
</div>
