<nav>
    <div class="aside pt-5">
        <app-sidebar-item *ngFor="let item of menu" [title]="item.name" [active]="item.active">
            <ng-template [ngIf]="item.list">
                <app-sidebar-item *ngFor="let subItem of item.list" [title]="subItem.name"
                                  [active]="subItem.active"></app-sidebar-item>
            </ng-template>
        </app-sidebar-item>
    </div>
</nav>
