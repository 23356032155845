import { Component, OnInit } from '@angular/core'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-page-onboard',
	templateUrl: './page-onboard.component.html',
	styleUrls: ['./page-onboard.component.scss'],
})
export class PageOnboardComponent implements OnInit {
	slides: any[] = []
	current: boolean | undefined
	urlPath = '/qr-code'

	constructor(private statsService: StatsService) {
		this.slides = [
			{
				id: '1',
				title: 'Boas-vindas!',
				description:
					'Vamos te explicar como funciona algumas opções <br />tque você vai encontrar por aqui.<br /><br /><strong>Podemos começar?</strong><br /><br />',
				image: '',
				logo: 'logo-hai-big.svg',
			},
			{
				id: '2',
				title: 'Status de serviços',
				description:
					'Agora com o novo sistema HAI, podemos verificar o status dos <br/> serviços que o sistema necessita para funcionar',
				image: 'status.svg',
				logo: '',
			},
			{
				id: '3',
				title: 'Menu principal',
				description:
					'Aqui você pode encontrar opções pontuais para executar ações sem <br/> precisar sair da tela que estiver utilizando.',
				image: 'menu.png',
				logo: '',
			},
			{
				id: '4',
				title: 'Etapas realizadas',
				description:
					'Você encontrará, do lado esquerdo da tela, o nome de cada etapa que já <br/> realizou e também a atual.',
				image: 'icon-onboarding-3.png',
				logo: '',
			},
			{
				id: '5',
				title: 'Realocar paciente',
				description:
					'Agora é possível realocar o paciente apenas com um clique e <br/> scanneando o qrCode na tela. Você também pode selecionar qual o <br/> tipo de atendimento no momento da realocação',
				image: 'qrcode.svg',
				logo: '',
			},
			{
				id: '6',
				title: 'Cancelar consulta',
				description:
					'O ícone abaixo está presente em quase todas as telas que você vai usar <br/> por aqui, com ele você pode realizar o cancelamento da consulta atual.',
				image: 'cancelar.svg',
				logo: '',
			},
			{
				id: '7',
				title: 'Central de ajuda',
				description:
					'Aqui você consegue tirar suas dúvidas de uma maneira mais simples com uma <br/> descrição ou se preferir assistir o video da explicação',
				image: 'faq.svg',
				logo: '',
			},
			{
				id: '8',
				title: 'Vamos começar?',
				description:
					'Agora você já está por dentro de tudo do aplicativo da HAI. <br /> Clique em começar para iniciarmos o atendimento.',
				image: 'icon-onboarding-4.png',
				logo: '',
			},
		]
	}

	ngOnInit(): void {
		this.statsService.stats('init_onboard', 'start')
	}

	ngOnDestroy() {
		this.statsService.stats('init_onboard', 'end')
	}

	title = 'ngSlick'

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		arrows: true,
		prevArrow: '.prev',
		nextArrow: '.next',
		fade: true,
	}

	change(e: any) {
		this.current = e.nextSlide === this.slides.length - 1
	}
}
