const api = 'https://hml-api.haitech.network'

export const environment = {
    api: `${api}/api/v1/main`,
    ws: 'https://hml.haitech.network',
	redirects: {
		roles: {
			cabin: '/status',
			suitcase: '/status',
			public: '/',
		},
	},
    env: 'hml',
	production: false,
}
