import { Component, Input, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth/auth.service'
import { Router } from '@angular/router'
import { PanelService } from '../../services/panel/panel.service'
import { SocketIoService } from '../../services/socket-io/socket-io.service'
import { ToastrService } from 'ngx-toastr'
import { AppointmentsService } from '../../services/appointments/appointments.service'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	showMenu: boolean = false
	isShowFloatMenu: boolean = false

	headerMenuList: any[] = []
	headerFloatButtonList: any[] = []

	@Input() public fButton?: boolean = true

	constructor(
		private authService: AuthService,
		private appointmentsService: AppointmentsService,
		private router: Router,
		private panelService: PanelService,
		private socketIoService: SocketIoService,
		private toast: ToastrService
	) {
		const self = this
		this.headerMenuList = [
			{ title: 'Realocar paciente', path: 'queue' },
			{ title: 'Tutorial', path: 'onboard' },
			{ title: 'FAQ', path: 'faq' },
			{ title: 'Status do sistema', path: 'status' },
			{ title: 'Checagem do sistema', path: 'checagem-do-sistema' },
		]
		this.headerFloatButtonList = [
			{
				title: 'Iniciar teleatendimento',
				fn() {
					const session = self.panelService.getSessionLocale()
					if (session?.id) {
						self.appointmentsService.screeningCompletedAppointment(
							session,
							{
								fnSuccess() {
									self.socketIoService.emitEventSocket(
										'updatePatientList',
										null,
										'panel_cmd'
									)
									setTimeout(() => {
										// @ts-ignore
										window.location = '/consulta'
									}, 500)
								},
								fnError(error) {
									self.toast.error(
										'Erro ao tentar abrir o Teleatendimento',
										'Tente novamente'
									)
								},
							}
						)
					} else {
						self.toast.error(
							'Você ainda não possui uma sessão válida!'
						)
					}
				},
			},
			{
				title: 'Cancelar atendimento',
				fn() {
					const session = self.panelService.getSessionLocale()
					if (session?.id) {
						self.appointmentsService.cancelAppointment(session, {
							fnSuccess() {
								self.socketIoService.emitEventSocket(
									'appointmentCanceled'
								)
								self.socketIoService.emitEventSocket(
									'appointmentCanceled',
									{
										qrCode: session?.hash,
									},
									'panel_cmd'
								)
								setTimeout(() => {
									self.panelService.cancelSession(true)
								}, 500)
							},
							fnError(error) {
								self.toast.error(
									'Erro ao tentar cancelar um atendimento!'
								)
							},
						})
					} else {
						const qrCode = self.panelService.getQrCodeLocale()
						self.socketIoService.emitEventSocket(
							'appointmentCanceled'
						)
						self.socketIoService.emitEventSocket(
							'appointmentCanceled',
							{
								qrCode,
							},
							'panel_cmd'
						)
						setTimeout(() => {
							self.panelService.cancelSession(true)
						}, 500)
					}
				},
			},
		]
	}

	ngOnInit(): void {
		window.addEventListener('resize', () => {
			this.closeMenu()
		})
	}

	toggleMenu(): void {
		this.showMenu = !this.showMenu
	}

	toggleFloatButton(): void {
		this.isShowFloatMenu = !this.isShowFloatMenu
	}

	closeMenu(): void {
		this.showMenu = false
	}

	logout() {
		this.authService.logout().subscribe(
			() => {
				this.authService.logOutSectionLocal().subscribe(() => {
					// @ts-ignore
					window.location = '/login'
				})
			},
			(err) => {
				console.warn(err)
			}
		)
	}
}
