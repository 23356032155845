import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import {PayloadSendExamsBody} from "../../../models/requests";
import {HelpersService} from "../../../services/helpers/helpers.service";
import {ExamsService} from "../../../services/exams/exams.service";
import {Exam, ExamFile} from "../../../models/exam";

@Component({
    selector: 'app-audio-recording',
    templateUrl: './audio-recording.component.html',
    styleUrls: ['./audio-recording.component.scss'],
})
export class AudioRecordingComponent implements OnInit {
    @Input() exam?: Exam
    @Output() public onCompleteRecorded: EventEmitter<any> = new EventEmitter()

    @ViewChild('audioCanvas', {static: false})
    public _audioCanvasRef?: ElementRef
    private audioCanvas?: HTMLCanvasElement

    showConfig?: boolean = false

    optionAudioInputSelected: string = 'default'
    optionsAudioInput: any[] = []

    audios: ExamFile[] = []
    audioPreview: string = ''

    leftchannel: any[] = []
    rightchannel: any[] = []
    recording: boolean = false
    recordingLength: number = 0
    volume: any = null
    audioInput: any = null
    sampleRate: any = null
    //@ts-ignore
    AudioContext: any = window.AudioContext || window.webkitAudioContext
    context: any = null
    analyser: any = null
    canvasCtx?: any
    stream: any = null
    tested: boolean = false

    currentExam: any

    constructor(
        private helpers: HelpersService,
        private examsService: ExamsService,
    ) {
    }

    ngOnInit(): void {
        this.initCurrentExam()
        this.init()
        setTimeout(() => {
            this.getExams()
        }, 10)
    }

    initCurrentExam() {
        if (this.exam?.children?.length) {
            this.currentExam = this.exam?.children[0]
        } else {
            this.currentExam = this.exam
        }
    }

    selectExam(item: any) {
        this.currentExam = item
    }

    getExams() {
        const self = this
        const slugs: string[] = []
        self.exam?.children?.forEach((item: any) => {
            slugs.push(item.slug)
        })

        self.examsService.getExams(slugs, (items: ExamFile[]) => {
            self.audios = items
        })
    }

    async init(): Promise<void> {
        try {
            this.stream = await this.getStream()
        } catch (e) {
            // infromar problema com permissão
        }
        this.audioCanvas = this._audioCanvasRef?.nativeElement

        if (this.audioCanvas) {
            this.canvasCtx = this.audioCanvas.getContext('2d')
            this.setUpRecording()
        }
    }

    getStream(constraints?: any) {
        if (!constraints) {
            constraints = {audio: true, video: false}
        }
        return navigator.mediaDevices.getUserMedia(constraints)
    }

    setUpRecording() {
        this.context = new this.AudioContext()
        this.sampleRate = this.context.sampleRate
        this.volume = this.context.createGain()
        this.audioInput = this.context.createMediaStreamSource(this.stream)
        this.analyser = this.context.createAnalyser()
        this.audioInput.connect(this.analyser)

        let bufferSize = 2048
        let recorder = this.context.createScriptProcessor(bufferSize, 2, 2)

        this.analyser.connect(recorder)
        recorder.connect(this.context.destination)
        recorder.onaudioprocess = (e: any) => {
            if (!this.recording) return
            let left = e.inputBuffer.getChannelData(0)
            let right = e.inputBuffer.getChannelData(1)
            if (!this.tested) {
                this.tested = true
                if (!left.reduce((a: number, b: number) => a + b)) {
                    alert('There seems to be an issue with your Mic')
                    this.stopRecordingAudio()
                    this.stream
                        .getTracks()
                        .forEach((track: MediaStreamTrack) => {
                            track.stop()
                        })
                    this.context.close()
                }
            }
            this.leftchannel.push(new Float32Array(left))
            this.rightchannel.push(new Float32Array(right))
            this.recordingLength += bufferSize
        }
        this.visualize()
    }

    mergeBuffers(channelBuffer: any, recordingLength: any) {
        let result = new Float32Array(recordingLength)
        let offset = 0
        let lng = channelBuffer.length
        for (let i = 0; i < lng; i++) {
            let buffer = channelBuffer[i]
            result.set(buffer, offset)
            offset += buffer.length
        }
        return result
    }

    interleave(leftChannel: any, rightChannel: any) {
        let length = leftChannel.length + rightChannel.length
        let result = new Float32Array(length)

        let inputIndex = 0

        for (let index = 0; index < length;) {
            result[index++] = leftChannel[inputIndex]
            result[index++] = rightChannel[inputIndex]
            inputIndex++
        }
        return result
    }

    writeUTFBytes(view: any, offset: any, string: any) {
        let lng = string.length
        for (let i = 0; i < lng; i++) {
            view.setUint8(offset + i, string.charCodeAt(i))
        }
    }

    startRecordingAudio() {
        this.recording = true
        this.leftchannel.length = this.rightchannel.length = 0
        this.recordingLength = 0
        if (!this.context) this.setUpRecording()
    }

    stopRecordingAudio() {
        this.recording = false

        let leftBuffer = this.mergeBuffers(
            this.leftchannel,
            this.recordingLength
        )
        let rightBuffer = this.mergeBuffers(
            this.rightchannel,
            this.recordingLength
        )
        let interleaved = this.interleave(leftBuffer, rightBuffer)

        let buffer = new ArrayBuffer(44 + interleaved.length * 2)
        let view = new DataView(buffer)

        this.writeUTFBytes(view, 0, 'RIFF')
        view.setUint32(4, 44 + interleaved.length * 2, true)
        this.writeUTFBytes(view, 8, 'WAVE')
        this.writeUTFBytes(view, 12, 'fmt ')
        view.setUint32(16, 16, true)
        view.setUint16(20, 1, true)
        view.setUint16(22, 2, true)
        view.setUint32(24, this.sampleRate, true)
        view.setUint32(28, this.sampleRate * 4, true)
        view.setUint16(32, 4, true)
        view.setUint16(34, 16, true)
        this.writeUTFBytes(view, 36, 'data')
        view.setUint32(40, interleaved.length * 2, true)

        let lng = interleaved.length
        let index = 44
        let volume = 1
        for (let i = 0; i < lng; i++) {
            view.setInt16(index, interleaved[i] * (0x7fff * volume), true)
            index += 2
        }

        const audio = new Blob([view], {type: 'audio/wav'})
        this.audioPreview = URL.createObjectURL(audio)

        if (this.audioPreview) {
            // SEND FILE API
            this.sendFile(audio)

            // TEST
            // this.testAudioRecord()
        }
    }

    testAudioRecord() {
        const audioPlayer = new Audio()
        audioPlayer.src = this.audioPreview
        audioPlayer.load()
        audioPlayer.play()
    }

    visualize() {
        const WIDTH = this.audioCanvas?.width || 100
        const HEIGHT = this.audioCanvas?.height || 100

        if (!this.analyser && !this.canvasCtx) return

        this.analyser.fftSize = 2048
        let bufferLength = this.analyser.fftSize
        // console.log(bufferLength);
        let dataArray = new Uint8Array(bufferLength)

        this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)

        let draw = () => {
            const drawVisual = requestAnimationFrame(draw)

            this.analyser.getByteTimeDomainData(dataArray)

            this.canvasCtx.fillStyle = 'rgb(255, 255, 255)'
            this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT)

            this.canvasCtx.lineWidth = 2
            this.canvasCtx.strokeStyle = 'rgb(57, 63, 101)'

            this.canvasCtx.beginPath()

            let sliceWidth = (WIDTH * 1.0) / bufferLength
            let x = 0

            for (let i = 0; i < bufferLength; i++) {
                let v = dataArray[i] / 128.0
                let y = (v * HEIGHT) / 2

                if (i === 0) {
                    this.canvasCtx.moveTo(x, y)
                } else {
                    this.canvasCtx.lineTo(x, y)
                }

                x += sliceWidth
            }

            this.canvasCtx.lineTo(WIDTH, HEIGHT / 2)
            this.canvasCtx.stroke()
        }

        draw()
    }

    sendFile(file: Blob) {
        const body: PayloadSendExamsBody = {
            file,
            examName: this.currentExam.name,
            examSlug: this.currentExam.slug,
            callback: () => {
                this.getExams()
            }
        }
        this.onCompleteRecorded.emit(body)
    }

    getDevices() {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            this.optionsAudioInput = []
            this.helpers.setDevicesInList(mediaDevices, this.optionsAudioInput, 'audioinput')
        })
    }

    async changeAudioInput() {
        if (this.optionAudioInputSelected !== '') {
            this.stream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.context?.close();

            this.stream = await this.getStream({
                audio: {
                    deviceId: {exact: this.optionAudioInputSelected},
                },
                video: false,
            })
            this.setUpRecording()
            this.closeModalConfiguration()
        }
    }

    openModalConfiguration(): void {
        this.showConfig = !this.showConfig

        if (this.showConfig) {
            this.getDevices()
        }
    }

    closeModalConfiguration(): void {
        this.showConfig = false
    }

    openModalExcludedExam(item: ExamFile) {
        this.examsService.modalExcludedExam(item, () => {
            this.getExams()
        })
    }
}
