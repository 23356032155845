import {ListHeader} from '../../models/list-header'
import {Component, OnInit} from '@angular/core'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {Router} from '@angular/router'
import {AppointmentsService} from '../../services/appointments/appointments.service'
import {PanelService} from '../../services/panel/panel.service'
import {HelpersService} from '../../services/helpers/helpers.service'
import {StatsService} from '../../services/stats/stats.service'
import {ModalReallocateComponent} from "../../components/modals/modal-reallocate/modal-reallocate.component";
import {SocketIoService} from "../../services/socket-io/socket-io.service";

@Component({
    selector: 'app-queue',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit {
    listHeaderPatientReallocation: ListHeader[]
    modalRef: any

    constructor(
        private modalReallocate: NgbModal,
        private router: Router,
        private appointmentsService: AppointmentsService,
        private panelService: PanelService,
        public helpers: HelpersService,
        private socketIoService: SocketIoService,
        private statsService: StatsService,
    ) {
        this.listHeaderPatientReallocation = [
            {
                value: 'date',
                label: 'Data da consulta',
                className: 'text-left',
            },
            {
                value: 'name',
                label: 'Nome',
                className: 'text-left',
            },
            {
                value: 'cpf',
                label: 'CPF',
                align: 'center',
            },
            {
                value: 'button',
                label: 'Realocar',
            },
        ]
    }

    listBody: any = []

    ngOnInit() {
        setTimeout(() => {
            this.statsService.stats('init_relocate_patient', 'start')
            this.getAllAppointments()
        }, 10)

        this.socketIoService.watchEventsSocket('qrCodeRead', () => {
            this.modalRef?.close()
            this.panelService.goToPanelExams(true)
        })
    }

    getAllAppointments() {
        const self = this

        this.appointmentsService.getAppointments({
            fnSuccess(response: any) {
                const {items} = response
                self.listBody = items.map((item: any) => {
                    item.patient = self.helpers.converterSnakeToCamelCase(
                        item.patient
                    )
                    return self.helpers.converterSnakeToCamelCase(item)
                })
                self.statsService.stats('init_relocate_patient', 'end')
            },
            fnError(error) {
            },
            fnFinalized() {
                //
            },
        })
    }

    relocate(item: any) {
        this.modalRef = this.modalReallocate.open(ModalReallocateComponent)
        this.modalRef.componentInstance.title = 'Deseja realocar o paciente?'
        this.modalRef.componentInstance.desc = 'Leia o QrCode para continuar'
        this.modalRef.componentInstance.patientId = item.patient.id
        this.modalRef.componentInstance.appointmentType = item.type
        this.modalRef.componentInstance.callbackConfirmation = () => {
            this.modalRef.close()
        }
    }

    backToQrCode() {
        this.router.navigate(['qr-code'])
    }
}
