import {Injectable} from '@angular/core'
import {HttpClient, HttpErrorResponse} from '@angular/common/http'
import {HelpersService} from '../helpers/helpers.service'
import {Store} from '@ngrx/store'
import {AppState} from '../../state/app-state'
import {
    PayloadSendExams,
    PayloadSendExamsBody,
    RequestItem,
    RequestsLists,
} from '../../models/requests'
import {Callbacks} from '../../models/callbacks'
import {environment} from '../../../environments/environment'
import {finalize} from 'rxjs/operators'
import {ToastrService} from 'ngx-toastr'
import {ModalDefaultComponent} from '../../components/modals/modal-default/modal-default.component'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {AuthService} from '../auth/auth.service'
import {ExamFile} from '../../models/exam'
import {
    HiddenLoading,
    ShowLoadingProgress,
} from '../../state/loading/loading.action'
import {PanelService} from '../panel/panel.service'
import {SocketIoService} from '../socket-io/socket-io.service'

@Injectable({
    providedIn: 'root',
})
export class ExamsService {
    requests: RequestsLists = {
        listProcess: [],
        listSuccess: [],
        listErrors: [],
    }

    private tries: number = 5

    constructor(
        private http: HttpClient,
        private helpers: HelpersService,
        private store: Store<AppState>,
        private toast: ToastrService,
        private modalDefault: NgbModal,
        private authService: AuthService,
        private panelService: PanelService,
        private socketIoService: SocketIoService
    ) {
    }

    sendFile(body: PayloadSendExamsBody) {
        const session = this.panelService.getSessionLocale()

        if (session) {
            const {file, examName, examSlug, callback} = body

            const formData = new FormData()
            formData.append('file', file)
            formData.append('exam_slug', examSlug)
            formData.append('exam_value', examName)
            formData.append('appointment_id', session.id)
            formData.append('patient_id', session.patient.id)

            this.sendExam(
                {
                    assign: examName,
                    body: formData,
                },
                callback
            )
        }
    }

    sendExam(payload: PayloadSendExams, callback?: Function) {
        const request = this.http.post(`${environment.api}/exams`, payload.body)
        this.addRequest(request, payload.assign, callback)
    }

    addRequest(request: any, assign: string, callback?: Function) {
        const item: RequestItem = {
            assign,
            tries: 0,
            req: (callbacks: Callbacks) => {
                request
                    .pipe(
                        finalize(() => {
                            if (callback) callback()
                            this.store.dispatch(HiddenLoading())
                            this.socketIoService.emitEventSocket(
                                'updatePatientExamList',
                                null,
                                'panel_cmd'
                            )
                        })
                    )
                    .subscribe(
                        (data: any) => {
                            callbacks.fnSuccess(data)
                        },
                        (error: HttpErrorResponse) => {
                            callbacks.fnError(error)
                        }
                    )
            },
        }

        this.requests.listProcess.push(item)

        this.execRequests()
    }

    execRequests() {
        const {listProcess} = this.requests

        while (listProcess.length) {
            const item = listProcess.pop()
            this.processRequest(item)
        }

        this.execAllRequestsErrors()
    }

    execAllRequestsErrors(force?: false) {
        const {listErrors} = this.requests

        while (listErrors.length) {
            const item = listErrors.shift()
            if (item) {
                if (force || item.tries < this.tries) {
                    this.processRequest(item)
                } else {
                    this.toast.error(
                        `${item.tries} tentativas sem sucesso de fazer o envio do ${item.assign}.`,
                        'Tente novamente mais tarde'
                    )
                    setTimeout(() => {
                        listErrors.push(item)
                    }, 250)
                }
            }
        }
    }

    processRequest(item?: RequestItem) {
        const self = this
        if (item) {
            const {listErrors, listSuccess} = this.requests

            item.tries++

            this.store.dispatch(ShowLoadingProgress())

            item.req({
                fnSuccess() {
                    listSuccess.push(item)
                    self.toast.success(`${item.assign} enviado com sucesso!`)
                },
                fnError() {
                    listErrors.push(item)
                },
            })
        }
    }

    getExams(slugs: string[], callbackSuccess: Function) {
        const session = this.panelService.getSessionLocale()

        if (session) {
            const values = slugs.join(',')
            this.store.dispatch(ShowLoadingProgress())

            this.http
                .get(`${environment.api}/exams`, {
                    params: {
                        exam_slugs: values,
                        appointment_id: session.id,
                    },
                })
                .pipe(
                    finalize(() => {
                        this.store.dispatch(HiddenLoading())
                    })
                )
                .subscribe(
                    (data: any) => {
                        console.log('GET EXAMS', data)
                        let {items} = data
                        items = items.map((item: ExamFile) => {
                            return {
                                ...this.helpers.converterSnakeToCamelCase(item),
                            }
                        })
                        if (callbackSuccess) callbackSuccess(items)
                    },
                    (error) => {
                        console.warn(error)
                    }
                )
        }
    }

    editExam(id: string, value: string, callbacks: Callbacks) {
        this.http
            .patch(`${environment.api}/exams/${id}`, {
                exam_value: value,
            })
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callbacks.fnSuccess(data)
                },
                (error) => {
                    callbacks.fnError(error)
                }
            )
    }

    deleteExam(id: string, callbacks: Callbacks) {
        this.http
            .delete(`${environment.api}/exams/${id}`)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callbacks.fnSuccess(data)
                    this.socketIoService.emitEventSocket(
                        'updatePatientExamList',
                        null,
                        'panel_cmd'
                    )
                },
                (error) => {
                    callbacks.fnError(error)
                }
            )
    }

    modalExcludedExam(item: ExamFile, callback?: Function) {
        const self = this
        const modalRef = self.modalDefault.open(ModalDefaultComponent)
        modalRef.componentInstance.title = `Excluir exame "${
            item.examValue || '--'
        }"?`
        modalRef.componentInstance.desc = 'Essa a ação não poderá ser desfeita.'
        modalRef.componentInstance.callbackConfirmation = () => {
            self.deleteExam(item.id, {
                fnSuccess() {
                    self.toast.success('Exame deletado com sucesso!')
                },
                fnError(error) {
                    self.toast.error(
                        'Ocorreu um problema ao deletar o exame',
                        'Tente novamente!'
                    )
                },
                fnFinalized() {
                    if (callback) callback()
                },
            })
            modalRef.close()
        }
    }

    getFile(path: string) {
        const token = this.authService.getToken()
        const options = {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }
        return fetch(path, options).then((res) => res.blob())
    }
}
