import {Component, OnInit} from '@angular/core'
import {HelpersService} from '../../services/helpers/helpers.service'
import {Router} from '@angular/router'
import {StatusService} from '../../services/status/status.service'
import {StatsService} from '../../services/stats/stats.service'

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
    optionsAudioInput: any[] = []
    optionsAudioOutput: any[] = []
    optionsVideoInput: any[] = []
    listApis: any[] = []
    statusApi = {
        error: false,
        incident: false,
        img: 'status-ok.svg',
        title: 'HAI está em funcionamento',
        desc: 'Se houver algum problema, verifique as conexões ou entre em contato com o suporte',
    }

    interval?: any
    time: number = 1000 * 15 //seconds

    constructor(
        private helpers: HelpersService,
        private router: Router,
        private statusService: StatusService,
        private statsService: StatsService
    ) {
    }

    ngOnInit(): void {
        this.init()

        clearInterval(this.interval)
        this.interval = setInterval(() => {
            this.init()
        }, this.time)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    init() {
        this.getDevices()
        this.getStatusApis()
    }

    getStatusApis() {
        this.statsService.stats('init_status_service', 'start')
        const self = this
        this.statusService.getStatusApis({
            fnSuccess(response?: any) {
                self.statsService.stats('init_status_service', 'end')
                const {data} = response
                self.listApis = Object.keys(data).map((key) => {
                    return {
                        api: key,
                        ...data[key],
                    }
                })
                let i = 0
                while (i < self.listApis.length) {
                    const item = self.listApis[i]
                    if (item.status === 'error' && !item.required) {
                        self.statusApi = {
                            error: false,
                            incident: true,
                            img: 'status-incident.svg',
                            title: 'HAI está em funcionamento',
                            desc: 'Estamos com um incidente mas nada que afetará a video conferência com o médico',
                        }
                    } else if (item.status === 'error') {
                        self.statusApi = {
                            error: true,
                            incident: false,
                            img: 'status-error.svg',
                            title: 'HAI está com instabilidade',
                            desc: 'Estamos com instabilidade, entre em contato com o suporte ou aguarde e tente novamente',
                        }
                        break
                    } else {
                        self.statusApi = {
                            error: false,
                            incident: false,
                            img: 'status-ok.svg',
                            title: 'HAI está em funcionamento',
                            desc: 'Se houver algum problema, verifique as conexões ou entre em contato com o suporte',
                        }
                    }
                    i++
                }
            },
            fnError(error) {
            },
        })
    }

    getDevices() {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            this.resetLists()

            this.helpers.setDevicesInList(
                mediaDevices,
                this.optionsAudioInput,
                'audioinput'
            )
            this.helpers.setDevicesInList(
                mediaDevices,
                this.optionsAudioOutput,
                'audiooutput'
            )
            this.helpers.setDevicesInList(
                mediaDevices,
                this.optionsVideoInput,
                'videoinput'
            )
        })
    }

    resetLists() {
        this.optionsAudioInput = []
        this.optionsAudioOutput = []
        this.optionsVideoInput = []
    }

    goToNext() {
        const hasVisited = JSON.parse(
            <string>localStorage.getItem('hasVisited')
        )

        if (JSON.parse(hasVisited)) {
            this.router.navigate(['/qr-code'])
        } else {
            localStorage.setItem('hasVisited', 'true')
            this.router.navigate(['/onboard'])
        }
    }

    isDisabledButton(): boolean {
        return (
            this.statusApi.error ||
            !this.optionsAudioInput.length ||
            !this.optionsAudioOutput.length
        )
    }
}
