import { NgModule } from '@angular/core'
// import {ErrorHandler, Injectable, NgModule} from '@angular/core'
// import * as Sentry from '@sentry/browser'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { StoreModule } from '@ngrx/store'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { NgxMaskModule } from 'ngx-mask'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { QRCodeModule } from 'angular2-qrcode'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { LoginComponent } from './pages/login/login.component'
import { AppointmentComponent } from './pages/appointment/appointment.component'
import { InputComponent } from './components/form/input/input.component'
import { SelectComponent } from './components/form/select/select.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { RadiosComponent } from './components/form/radios/radios.component'
import { HeaderComponent } from './components/header/header.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { WorkbenchLayerComponent } from './components/workbench-layer/workbench-layer.component'
import { PatientInfoComponent } from './components/patient-info/patient-info.component'
import { VideoCallComponent } from './components/video-call/video-call.component'
import { stateReducers } from './state/reducers'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ImageComponent } from './components/image/image.component'
import { ButtonTooltipComponent } from './components/button-tooltip/button-tooltip.component'
import { TabComponent } from './components/tabs/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { BoxComponent } from './components/box/box.component'
import { AudioComponent } from './components/audio/audio.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { ModalReallocateComponent } from './components/modals/modal-reallocate/modal-reallocate.component'
import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { AlertComponent } from './components/alert/alert.component'
import { SidebarItemComponent } from './components/sidebar/sidebar-item/sidebar-item.component'
// import {environment} from '../environments/environment'
import { VersionComponent } from './components/version/version.component'
import { InitQrCodeComponent } from './pages/init-qr-code/init-qr-code.component'
import { PanelComponent } from './pages/panel/panel.component'
import { StatusComponent } from './pages/status/status.component'
import { OnboardComponent } from './components/onboard/onboard.component'
import { StatusImageComponent } from './components/status-image/status-image.component'
import { CardExamComponent } from './components/card-exam/card-exam.component'
import { TitleComponent } from './components/title/title.component'
import { SelectExamComponent } from './components/select-exam/select-exam.component'
import { ClosureComponent } from './pages/closure/closure.component'
import { StatusLoginComponent } from './components/status-login/status-login.component'
import { QueueComponent } from './pages/queue/queue.component'
import { AudioRecordingComponent } from './components/exams/audio-recording/audio-recording.component'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { PageOnboardComponent } from './pages/onboard/page-onboard.component'
import { FaqComponent } from './pages/faq/faq.component'
import { ImagingExamsComponent } from './components/exams/imaging-exams/imaging-exams.component'
import { ConfigComponent } from './pages/config/config.component'
import { TestingInputAudioComponent } from './components/testing/testing-input-audio/testing-input-audio.component'
import { TestingOutputAudioComponent } from './components/testing/testing-output-audio/testing-output-audio.component'
import { TestingInputVideoComponent } from './components/testing/testing-input-video/testing-input-video.component'
import { TestingApisComponent } from './components/testing/testing-apis/testing-apis.component'
import { ModalTryAgainComponent } from './components/modals/modal-try-again/modal-try-again.component'
import { ModalActiveSectionComponent } from './components/modals/modal-active-section/modal-active-section.component';
import { SuccessRedirectComponent } from './components/success-redirect/success-redirect.component';
import { SuccessConexaComponent } from './pages/success-conexa/success-conexa.component';
import { VisualAcuityComponent } from './components/exams/visual-acuity/visual-acuity.component'

// Sentry.init({
// 	dsn: 'https://bc11218c75db49199741bc5846633773@o246769.ingest.sentry.io/5881792',
// 	environment: environment.production ? 'prod' : 'dev',
// })

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
// 	handleError(error: any) {
// 		Sentry.captureException(error.originalError || error)
// 	}
// }

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		LoginComponent,
		AppointmentComponent,
		InputComponent,
		SelectComponent,
		CheckboxComponent,
		RadiosComponent,
		HeaderComponent,
		SidebarComponent,
		TableListComponent,
		WorkbenchLayerComponent,
		PatientInfoComponent,
		VideoCallComponent,
		ImageComponent,
		ButtonTooltipComponent,
		TabComponent,
		TabsComponent,
		AccordionComponent,
		BoxComponent,
		AudioComponent,
		SetValueDirective,
		SetFormControlErrorDirective,
		ModalDefaultComponent,
		LoadingGlobalComponent,
		LoadingLocalComponent,
		LoadingProgressComponent,
		AlertComponent,
		SidebarItemComponent,
		VersionComponent,
		InitQrCodeComponent,
		PanelComponent,
		StatusComponent,
		OnboardComponent,
		StatusImageComponent,
		CardExamComponent,
		ClosureComponent,
		TitleComponent,
		StatusImageComponent,
		CardExamComponent,
		SelectExamComponent,
		StatusLoginComponent,
		AudioRecordingComponent,
		StatusLoginComponent,
		QueueComponent,
		AudioRecordingComponent,
		PageOnboardComponent,
		FaqComponent,
		ImagingExamsComponent,
		ConfigComponent,
		TestingInputAudioComponent,
		TestingOutputAudioComponent,
		TestingInputVideoComponent,
		TestingApisComponent,
		ModalReallocateComponent,
		ModalTryAgainComponent,
		ModalActiveSectionComponent,
  SuccessRedirectComponent,
  SuccessConexaComponent,
  VisualAcuityComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LazyLoadImageModule,
		StoreModule.forRoot({ ...stateReducers }, {}),
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
		}),
		QRCodeModule,
		SlickCarouselModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		// {
		//     provide: ErrorHandler,
		//     useClass: SentryErrorHandler,
		// },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
