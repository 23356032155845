import { Component, OnInit } from '@angular/core'
import { StatusService } from '../../../services/status/status.service'
import { StatsService } from '../../../services/stats/stats.service'
import {
	HiddenLoading,
	ShowLoadingProgress,
} from '../../../state/loading/loading.action'
import { Store } from '@ngrx/store'
import { AppState } from '../../../state/app-state'

@Component({
	selector: 'app-testing-apis',
	templateUrl: './testing-apis.component.html',
	styleUrls: ['./testing-apis.component.scss'],
})
export class TestingApisComponent implements OnInit {
	listApis: any[] = []
	statusApi = {
		error: false,
		incident: false,
		img: 'status-ok.svg',
		title: 'HAI está em funcionamento',
		desc: 'Se houver algum problema, verifique as conexões ou entre em contato com o suporte',
	}

	constructor(
		private statusService: StatusService,
		private statsService: StatsService,
		private store: Store<AppState>
	) {}

	ngOnInit(): void {
		this.getStatusApis()
	}

	getStatusApis() {
		this.statsService.stats('init_status_service', 'start')
		this.store.dispatch(ShowLoadingProgress())
		const self = this
		this.statusService.getStatusApis({
			fnSuccess(response?: any) {
				self.statsService.stats('init_status_service', 'end')
				const { data } = response
				self.listApis = Object.keys(data).map((key) => {
					return {
						api: key,
						...data[key],
					}
				})
				let i = 0
				while (i < self.listApis.length) {
					const item = self.listApis[i]
					if (item.status === 'error' && !item.required) {
						self.statusApi.incident = true
						self.statusApi.desc =
							'Estamos com um incidente mas nada que afetará a video conferência com o médico'
						self.statusApi.img = 'status-incident.svg'
					} else if (item.status === 'error') {
						self.statusApi.error = true
						self.statusApi.title = 'HAI está com instabilidade'
						self.statusApi.desc =
							'Estamos com instabilidade, entre em contato com o suporte ou aguarde e tente novamente'
						self.statusApi.img = 'status-error.svg'
						break
					}
					i++
				}
			},
			fnFinalized() {
				self.store.dispatch(HiddenLoading())
			},
			fnError(error) {
				self.store.dispatch(HiddenLoading())
			},
		})
	}
}
