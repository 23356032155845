import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HelpersService } from '../../../services/helpers/helpers.service'

@Component({
	selector: 'app-testing-output-audio',
	templateUrl: './testing-output-audio.component.html',
	styleUrls: ['./testing-output-audio.component.scss'],
})
export class TestingOutputAudioComponent implements OnInit {
	@ViewChild('audioElement', { static: false })
	public _audioRef?: ElementRef
	private audio?: ElementRef

	showConfig: boolean = false
	optionAudioOutputSelected: string = 'default'
	optionsAudioOutput: any[] = []
	currentSelected: number = 0

	constructor(private helpers: HelpersService) {}

	ngOnInit(): void {
		this.getDevices()
	}

	ngAfterViewInit() {
		this.audio = this._audioRef?.nativeElement
	}

	getDevices() {
		navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
			this.optionsAudioOutput = []

			this.helpers.setDevicesInList(
				mediaDevices,
				this.optionsAudioOutput,
				'audiooutput'
			)
			console.log(this.optionsAudioOutput)
		})
	}

	async changeAudioOutput(val: any, current: number) {
		this.currentSelected = current
		this.optionAudioOutputSelected = val
		if (this.optionAudioOutputSelected !== '') {
			const localDevice = this.optionAudioOutputSelected
			const audioElement = this.audio
			if (audioElement) {
				//@ts-ignore
				audioElement.setSinkId(localDevice)
			}
		}
		this.closeModalConfiguration()
		console.log(this.optionAudioOutputSelected)
	}

	openModalConfiguration(): void {
		this.showConfig = !this.showConfig

		if (this.showConfig) {
			this.getDevices()
		}
	}

	closeModalConfiguration(): void {
		this.showConfig = false
	}
}
