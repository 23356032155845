import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal-try-again',
	templateUrl: './modal-try-again.component.html',
	styleUrls: ['./modal-try-again.component.scss'],
})
export class ModalTryAgainComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() callbackCancel?: Function
	@Input() title?: string
	@Input() desc?: string

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
		if (this.callbackConfirmation) this.callbackConfirmation()
		this.close()
	}

    cancel() {
        if (this.callbackCancel) this.callbackCancel()
        this.close()
    }
}
