<div class="wrapper-visual-acuity">
	<div class="row-visual-acuity" *ngFor="let list of letters; index as i">
		<span></span>
		<span></span>
		<div class="items-visual-acuity">
			<span
				*ngFor="let letter of list"
				[style]="'font-size: ' + getSize(i + 1).fontSize + 'px'"
				>{{ letter }}</span
			>
		</div>
		<span class="index-row">{{ i + 1 }}</span>
		<span class="size-row">{{ getSize(i + 1).size }}</span>
		<span></span>
	</div>
</div>
