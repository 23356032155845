import { ActionReducerMap } from '@ngrx/store'
import { userReducer } from './user/user.reducer'
import { patientReducer } from './patient/patient.reducer'
import { loadingReducer } from './loading/loading.reducer'
import {stepsAppointmentReducer} from "./steps-appointment/steps-appointment.reducer";

export const stateReducers: ActionReducerMap<any> = {
	user: userReducer,
	patient: patientReducer,
    stepsAppointment: stepsAppointmentReducer,
	loading: loadingReducer,
}
