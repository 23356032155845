<app-loading-progress *ngIf="loading"></app-loading-progress>
<main>
	<div class="container">
		<app-header [fButton]="true"></app-header>
		<div>
			<div
				[class.wrapper-content-open-menu]="isShowPatientInfoMenu"
				class="wrapper-content"
				[class.center]="!examIsCabin && !isWaiting"
			>
				<div [class.aside-open-menu]="isShowPatientInfoMenu">
					<app-sidebar></app-sidebar>
				</div>
				<div class="content-feedback">
					<section>
						<app-onboard
							*ngIf="!examIsCabin && isWaiting"
							title="Aguarde"
							description="Um procedimento está sendo <br /> realizado no tablet"
							direction="inline"
							src="/panel/aguarde.png"
						></app-onboard>
						<app-onboard
							*ngIf="!examIsCabin && isWelcome"
							title="Boas-vindas!"
							description="Vamos começar o seu atendimento?"
							src="/panel/boas-vindas-dev.svg"
						></app-onboard>
						<app-onboard
							*ngIf="!examIsCabin && isPatientInfo"
							title="Dados Pessoais"
							description="Aguarde, estamos coletando os seus dados."
							src="/panel/dados-pessoais.png"
						>
							<app-patient-info
								[patientValues]="patientValues"
								imgClose="assets/images/arrow-right-blue.svg"
								imgOpen="/assets/images/button-side-menu.svg"
								[isShowMenu]="isShowPatientInfoMenu"
								(isShowMenuChanged)="togglePatientInfoMenu()"
								(onConfirm)="personalDataConfirm()"
							></app-patient-info>
						</app-onboard>
						<app-onboard
							*ngIf="!examIsCabin && isRecordingCompliance"
							title="Gravar áudio"
							direction="reverse"
							description="Relate como está se sentindo e o motivo por <br/> ter vindo, em voz alta."
							src="/panel/scale-audio.gif"
						></app-onboard>
						<app-onboard
							*ngIf="!examIsCabin && specialtySelected"
							title="Especialidade"
							[subTitle]="specialty"
							description="Você será atendido por este profissional"
							direction="reverse"
							src="/panel/specialty.svg"
						></app-onboard>
						<app-onboard
							*ngIf="!examIsCabin && exam"
							title="Exame selecionado"
							[subTitle]="exam?.name"
							direction="reverse"
							description="aguarde um momento"
							src="/panel/exame-selecionado.png"
						></app-onboard>
						<app-onboard
							*ngIf="!examIsCabin && yourTurnHasCome"
							title="Chegou a sua vez!"
							description="O seu atendimento já vai começar"
							src="/panel/chegou-sua-vez.png"
							direction="inline"
						></app-onboard>
					</section>
					<section
						*ngIf="
							examIsCabin &&
							exam?.resultType === 'image' &&
							_reload
						"
					>
						<app-imaging-exams
							[exam]="exam"
							(onCompleteCapture)="examsService.sendFile($event)"
						></app-imaging-exams>
					</section>

					<section
						*ngIf="
							examIsCabin &&
							exam?.resultType === 'audio' &&
							_reload
						"
					>
						<app-audio-recording
							[exam]="exam"
							(onCompleteRecorded)="examsService.sendFile($event)"
						></app-audio-recording>
					</section>

					<section
						class="visual_acuity"
						*ngIf="
							examIsCabin &&
							exam?.slug === 'visual_acuity' &&
							_reload
						"
					>
						<app-visual-acuity></app-visual-acuity>
					</section>

					<section class="section-finish" *ngIf="examIsCabin">
						<button class="btn btn-primary" (click)="finishExam()">
							Encerrar coleta deste exame
						</button>
					</section>
				</div>
				<app-status-login></app-status-login>
			</div>
		</div>
	</div>
</main>
