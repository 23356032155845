import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { environment } from '../../../environments/environment'
import { Socket } from 'socket.io-client/build/esm/socket'
import * as io from 'socket.io-client'
import { User } from '../../models/user'
import { PanelService } from '../panel/panel.service'
import { Session } from '../../models/session'
import {AuthService} from "../auth/auth.service";

@Injectable({
	providedIn: 'root',
})
export class SocketIoService {
	private socket?: Socket
	private user: User
	private session: Session | null

	constructor(
		private http: HttpClient,
		private helpers: HelpersService,
		private panelService: PanelService,
        private authService: AuthService
	) {
		this.user = this.authService.getUserLocale()
		this.session = this.panelService.getSessionLocale()
	}

	getSocket(): Socket | undefined {
		const token = localStorage.getItem('token') || ''
		let socket: Socket | undefined
		if (token) {
			const { user } = this.helpers.decodePayloadJWT(token)
			const client = this.authService.getClient(user.clients)

			socket = io.connect(environment.ws, {
				auth: {
					access_token: token,
					entity_type: 'cabin',
					cabin: { id: user.id, name: user.name },
					client: { id: client?.id, name: client?.name },
				},
			})
		}

		//@ts-ignore
		window.socket = socket
		return socket
	}

	watchEventsSocket(action: string, handler: Function) {
		const channels = ['tablet_cmd', 'panel_cmd']
		this.socket = this.getSocket()

		channels.forEach((channel) => {
			this.socket?.on(channel, (data, callback) => {
				this.onContentEvent(data, callback, action, handler)
			})
		})
	}

	emitEventSocket(action: string, body?: any, ev: string = 'cabin_cmd') {
        const token = localStorage.getItem('token') || ''
        const { user } = this.helpers.decodePayloadJWT(token)
        const client = this.authService.getClient(user.clients)
        const qrCode = this.panelService.getQrCodeLocale()

		this.socket?.emit(
			ev,
			{
				action: action,
				appointmentId: this.session?.id || null,
				patientId: this.session?.patient.id || null,
				client: {
					id: client?.id,
					name: client?.name,
				},
				content: {
					qrCode: qrCode ? qrCode : this.session?.hash ? this.session?.hash : '',
					...body,
				},
			},
			(response: any) => {
				console.log(`response: ${JSON.stringify(response)}`)
			}
		)
	}

	onContentEvent(
		data: any,
		callback: Function,
		action: string,
		handler: Function
	) {
		this.session = this.panelService.getSessionLocale()
		const qrCode = this.panelService.getQrCodeLocale() || this.session?.hash

		console.log('qrCode', qrCode)
		if (qrCode === data.content.qrCode && action === data.action) {
			handler(data)
			if (callback) callback({ status: 'ok' })
		}
	}
}
