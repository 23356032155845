import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { interval } from 'rxjs'

@Component({
	selector: 'app-audio',
	templateUrl: './audio.component.html',
	styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit {
	@Input() public src?: string
	@Input() public title?: string
	@Input() public autoplay: boolean = false
	@Input() public showStateLabel: boolean = false
	@Input() public volume: number = 1.0
	@ViewChild('audioElement', { static: false })
	public _audioRef?: ElementRef
	private audio?: HTMLMediaElement

	public duration: number | undefined = 0
	public current: number = 0
	public audioTimer: string = '0:00'

	constructor() {}

	ngOnInit(): void {}

	public pause(): void {
		if (this.audio) {
			this.audio.pause()
		}
	}

	public get paused(): boolean {
		if (this.audio) {
			return this.audio.paused
		} else {
			return true
		}
	}

	public play(): void {
		if (this.audio && this.audio.readyState >= 2) {
			this.audio.play()
		}
	}

	public ngAfterViewInit() {
		this.audio = this._audioRef?.nativeElement
		if (this.audio) {
			this.audio.volume = this.volume
			this.audio.autoplay = this.autoplay
			this.timeUpdate()
		}
	}

	public setPos(): void {
		if (this.audio) {
			this.duration = Math.floor(this.audio.duration)
			this.current = parseInt(String(this.audio.currentTime), 10)

			this.audioTimer = this.getTimeCodeFromNum(
				this.audio.duration - this.audio.currentTime
			)
		}
	}

	timeUpdate(): void {
		this.audio?.addEventListener('timeupdate', () => {
			let curTime = parseInt(`${this.audio?.currentTime}`, 10)
			this.duration = Math.floor(this.audio?.duration || 0)
			this.current = curTime
		})
	}

	setDuration(value: any): void {
		if (this.audio) this.audio.currentTime = Math.floor(value)
	}

	getTimeCodeFromNum(num: any) {
		let seconds = parseInt(num)
		let minutes = parseInt(String(seconds / 60))
		seconds -= minutes * 60
		const hours = parseInt(String(minutes / 60))
		minutes -= hours * 60

		if (hours === 0)
			return `${minutes}:${String(seconds % 60).padStart(2, '0')}`
		return `${String(hours).padStart(2, '0')}:${minutes}:${String(
			seconds % 60
		).padStart(2, '0')}`
	}
}
