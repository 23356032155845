<div class="modal-body">
    <h5 *ngIf='title'>{{title}}</h5>
    <p *ngIf='desc'>{{desc}}</p>
    <div class="text-center">
        <qr-code
            class="qr-code"
            [value]="qrCode"
            [size]="300"
        ></qr-code>
    </div>
    <div class="appointment-type">
        <h6>Tipo do atendimento: </h6>
        <div class="controls">
            <div class="control">
                <label for="appointment">Consulta</label>
                <input id="appointment" type="radio" name="appointment-type" value="appointment"
                       [(ngModel)]="appointmentType" (change)="changeValueAppointmentType()"/>
            </div>
            <div class="control">
                <label for="exam">Exame</label>
                <input id="exam" type="radio" name="appointment-type" value="exams" [(ngModel)]="appointmentType"
                       (change)="changeValueAppointmentType()"/>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-xs" (click)="close()">
        Fechar
    </button>
</div>
