import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
	public showPass: boolean = false

	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() placeholder?: string
	@Input() typeInput?: string
	@Input() labelName?: string
	@Input() callout?: string
	@Input() inputModel: any
	@Input() inputMask: string = ''

	constructor() {}

	ngOnInit(): void {}

	toggleInputPass(): void {
		this.showPass = !this.showPass
	}
}
