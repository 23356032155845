import {Component} from '@angular/core'
import {Observable} from 'rxjs'
import {Store} from '@ngrx/store'
import {AppState} from './state/app-state'
import {environment} from "../environments/environment";
import {Loading} from "./models/loading";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private loading$?: Observable<Loading>
    loading?: Loading = {
        loading: false,
        loadingProgress: false
    }

    constructor(
        private store: Store<AppState>,
    ) {
        setTimeout(() => {
            this.loading$ = this.store.select('loading')
            this.loading$.subscribe((state) => {
                this.loading = state
            })
        }, 10)
    }

    ngOnInit(): void {
        // if (environment.env !== 'dev') {
        //     window.addEventListener("beforeunload", (e) => {
        //         let confirmationMessage = "\o/";
        //         (e || window.event).returnValue = Boolean(confirmationMessage);
        //         return confirmationMessage
        //     });
        // }
    }
}
