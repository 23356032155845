import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ExamsService} from "../../services/exams/exams.service";
import {PayloadEditExam} from "../../models/exam";

@Component({
    selector: 'app-card-exam',
    templateUrl: './card-exam.component.html',
    styleUrls: ['./card-exam.component.scss'],
})
export class CardExamComponent implements OnInit {
    @Input() id: string = ''
    @Input() title: string = ''
    @Input() image: string = ''
    @Input() val?: any
    @Output() emmitEventRemove: EventEmitter<any> = new EventEmitter()
    @Output() emmitEventSave: EventEmitter<any> = new EventEmitter()

    blob: string = ''

    isEditing: boolean = false
    value: string = ''

    constructor(private examsService: ExamsService) {
    }

    ngOnInit(): void {
        this.value = this.title
        this.examsService.getFile(this.image)
            .then((data) => {
                this.blob = URL.createObjectURL(data)
            })
    }

    editText() {
        this.isEditing = true
    }

    saveText() {
        const payload: PayloadEditExam = {
            value: this.value,
            callback: () => {
                this.isEditing = false
            }
        }
        this.emmitEventSave.emit(payload)
    }

    removeImageList() {
        this.emmitEventRemove.emit('remove')
    }
}
