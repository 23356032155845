<div class="appointment">
    <app-header></app-header>
    <div class="container">
        <div class="row">
            <div class="col-4 align-self-center text-center">
                <figure class="text-center pb-4">
                    <img
                        draggable="false"
                        [src]="client?.logo"
                        [alt]="client?.name"
                        [title]="client?.name"
                    />
                </figure>
                <p class="pb-3"><span>Paciente:</span> {{patient?.name}} {{patient?.lastName}}</p>
                <p *ngIf="session?.doctor"><span>Dr(a):</span> {{session?.doctor?.name}} {{session?.doctor?.lastName}}</p>
            </div>
            <div class="col-8" [class.toggle-flex]="true">
                <app-video-call
                    *ngIf="token && session && session.hash"
                    [token]="token"
                    [roomName]="session.hash"
                ></app-video-call>
            </div>
        </div>
    </div>
</div>

<app-workbench-layer [open]="showExams" (emmitEvent)="layerExams()">
    <div workbenchContent class="pt-4">
        <div class="pt-5">
            <h2>{{exam?.name}}</h2>
        </div>
        <section *ngIf="exam?.resultType === 'image' && _reload">
            <app-imaging-exams
                [exam]="exam"
                (onCompleteCapture)="examsService.sendFile($event)"
            ></app-imaging-exams>
        </section>

        <section *ngIf="exam?.resultType === 'audio' && _reload">
            <app-audio-recording
                [exam]="exam"
                (onCompleteRecorded)="examsService.sendFile($event)"
            ></app-audio-recording>
        </section>

        <section
            class="visual_acuity"
            *ngIf="
							examIsCabin &&
							exam?.slug === 'visual_acuity' &&
							_reload
						"
        >
            <app-visual-acuity></app-visual-acuity>
        </section>
    </div>
</app-workbench-layer>
