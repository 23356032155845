import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth/auth.service'
import { Router } from '@angular/router'
import { PanelService } from '../../services/panel/panel.service'
import { StepsAppointment } from '../../models/stepsAppointment'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	private menu$?: Observable<StepsAppointment[]>
	menu: StepsAppointment[] = []

	constructor(
		private authService: AuthService,
		private router: Router,
		private panelService: PanelService,
		private store: Store<AppState>
	) {
		this.menu$ = this.store.select('stepsAppointment')
		this.menu$?.subscribe((state) => {
			this.menu = state
		})
	}

	ngOnInit(): void {
		this.menu = this.panelService.getStepsAppointmentLocale()
	}

	logout() {
		this.authService.logout().subscribe(
			() => {
				this.authService.logOutSectionLocal().subscribe(() => {
					// @ts-ignore
					window.location = '/login'
				})
			},
			(err) => {
				console.warn(err)
			}
		)
	}
}
